import React, { useRef, useState } from 'react';
import Bill from '../../components/Bill';
import { validationInformationSchema } from '../../utils/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { signupApi } from '../../redux/features/signup/signupSlice';
import { TailSpin } from 'react-loader-spinner';
import ErrorBanner from '../ErrorBanner';
import { state_city } from '../../utils/state_cities';
import { Link } from 'react-router-dom';
import { memo } from 'react';
import PropTypes from 'prop-types';

const Information = ({ handleInformation }) => {
  const { personalDetail } = useSelector((state) => state.signup);
  const [errors, setErrors] = useState('');
  const [showError, setShowError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showErrorDetail, setShowErrorDetail] = useState({
    isError: true,
    message: '',
  });
  const [isSameAddress, setIsSameAddress] = useState(true);
  const [passwordToggle, setpasswordToggle] = useState(true);
  const [confirmPasswordToggle, setConfirmPasswordToggle] = useState(true);
  const formRef = useRef(null);
  let packagePlan = localStorage.getItem('selectedPlan');
  let selectedPlan = packagePlan ? JSON.parse(packagePlan) : false;
  //fetch email from local storage
  let defaultEmail = localStorage.getItem('joinEmail');

  let statelist = Object.keys(state_city);
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    userType: personalDetail?.userType
      ? personalDetail?.userType
      : 'individual',
    firstName: personalDetail?.firstName ? personalDetail?.firstName : '',
    lastName: personalDetail?.lastName ? personalDetail?.lastName : '',
    email: defaultEmail ? defaultEmail : '',
    phoneNumber: personalDetail?.phoneNumber ? personalDetail?.phoneNumber : '',
    company: personalDetail?.company ? personalDetail?.company : '',
    DOB: personalDetail?.DOB ? personalDetail?.DOB : '',
    password: personalDetail?.password ? personalDetail?.password : '',
    confirmPassword: personalDetail?.confirmPassword
      ? personalDetail?.confirmPassword
      : '',
    address: personalDetail?.address ? personalDetail?.address : '',
    address2: personalDetail?.address2 ? personalDetail?.address2 : '',
    state: personalDetail?.state ? personalDetail?.state : '',
    city: personalDetail?.city ? personalDetail?.city : '',
    zipCode: personalDetail?.zipCode ? personalDetail?.zipCode : '',
    billingZipCode: personalDetail?.billingZipCode
      ? personalDetail?.billingZipCode
      : '',
    billingCity: personalDetail?.billingCity ? personalDetail?.billingCity : '',
    billingOptionalAddress: personalDetail?.billingOptionalAddress
      ? personalDetail?.billingOptionalAddress
      : '',
    billingAddress: personalDetail?.billingAddress
      ? personalDetail?.billingAddress
      : '',
    billingState: personalDetail?.billingState
      ? personalDetail?.billingState
      : '',
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    // Validation
    try {
      validationInformationSchema.validateSyncAt(name, { [name]: value });
      setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
    } catch (error) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: error.message,
      }));
    }
  };

  const handlePasswordChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    try {
      validationInformationSchema.validateSyncAt(name, { [name]: value });
      if (name === 'password' && value !== formData.confirmPassword) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          confirmPassword: 'Password and Confirm Password should match',
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          confirmPassword: '',
        }));
      }
      setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
    } catch (error) {
      if (name === 'confirmPassword' && value === formData.password) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          confirmPassword: '',
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: error.message,
        }));
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await validationInformationSchema.validate(formData, {
        abortEarly: false,
      });
      setErrors('');
      const {
        userType,
        company,
        address2,
        city,
        state,
        billingAddress,
        ...personalDetail
      } = formData;
      const obj = {
        userType: userType === 'organization' ? 1 : 0,
        company: userType === 'organization' ? company : '',
        address2,
        city,
        state,
        billingAddress,
        ...personalDetail,
      };

      if (!state_city[state].includes(city)) {
        setShowError(true);
        setShowErrorDetail({
          isError: false,
          message: 'Not a valid United States city.',
        });
        setTimeout(() => {
          setShowError(false);
          setShowErrorDetail({
            isError: true,
            message: '',
          });
        }, 3000);
        setIsLoading(false);
      } else if (!isSameAddress && billingAddress === '') {
        setShowError(true);
        setShowErrorDetail({
          isError: false,
          message: 'Please provide the billing options first!',
        });
        setTimeout(() => {
          setShowError(false);
          setShowErrorDetail({
            isError: true,
            message: '',
          });
        }, 3000);
        setIsLoading(false);

        return false;
      } else {
        dispatch(signupApi(obj));
        localStorage.setItem('userData', JSON.stringify(obj));
        setIsLoading(false);
        handleInformation('billing');
        window.scrollTo(0, 0);
        // handleTabAction("profile", "billing");
      }
    } catch (error) {
      const validationErrors = {};
      error.inner.forEach((err) => {
        if (err.path) {
          validationErrors[err.path] = err.message;
        } else {
          validationErrors['DOB'] = err.message;
        }
      });

      if (Object.entries(validationErrors).length === 0) {
        setShowError(true);
        setShowErrorDetail({
          isError: true,
          message: 'Please fill all the required fields.',
        });
        setTimeout(() => {
          setShowError(false);
          setShowErrorDetail({
            isError: true,
            message: '',
          });
        }, 3000);
      }
      setErrors(validationErrors);
      setIsLoading(false);
    }
  };

  const handleManualSubmit = async (e) => {
    e.preventDefault();
    const form = formRef.current;
    if (form.checkValidity()) {
      setIsLoading(true);
      await handleSubmit(e);
    } else {
      setShowError(true);
      setShowErrorDetail({
        isError: true,
        message: 'Please fill all the required fields.',
      });
      setTimeout(() => {
        setShowError(false);
        setShowErrorDetail({
          isError: true,
          message: '',
        });
      }, 3000);
      form.reportValidity();
    }
  };

  const styleClass = {
    color: 'red',
  };

  const handleClose = () => {
    setShowError(false);
    setShowErrorDetail({
      isError: true,
      message: '',
    });
  };

  return (
    <>
      <div className="join-form-user p-0 p-md-2">
        <div className="container-fluid">
          <div className="row">
            {showError && (
              <ErrorBanner error={showErrorDetail} handleClose={handleClose} />
            )}
            <div className="col-lg-8">
              <form className="details-user-form" ref={formRef}>
                <div className="row g-3">
                  <div className="col-12 col-md-6">
                    <div className="radioBtn">
                      <input
                        className="radio-input"
                        type="radio"
                        name="userType"
                        id="myradio1"
                        value="individual"
                        checked={formData.userType === 'individual'}
                        onChange={handleInputChange}
                      />
                      <label htmlFor="myradio1" className="radio__label">
                        <p className="text-left">For individual</p>
                      </label>
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="radioBtn d-none">
                      <input
                        className="radio-input"
                        type="radio"
                        name="userType"
                        id="myradio2"
                        value="organization"
                        checked={formData.userType === 'organization'}
                        onChange={handleInputChange}
                      />
                      <label htmlFor="myradio2" className="radio__label">
                        <p>For organization</p>
                      </label>
                    </div>
                  </div>
                  <h4 className="details">PERSONAL DETAILS</h4>
                  <div className="col-md-6">
                    <label
                      htmlFor="firstName"
                      className="form-label label-text"
                    >
                      First name*
                    </label>
                    <input
                      type="text"
                      className="form-control input-outline"
                      // placeholder="First name*"
                      value={formData.firstName}
                      name="firstName"
                      onChange={handleInputChange}
                      required
                    />
                    {errors.firstName && (
                      <div style={styleClass}>{errors.firstName}</div>
                    )}
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="Lastname" className="form-label label-text">
                      Last name*
                    </label>
                    <input
                      type="text"
                      className="form-control input-outline"
                      // placeholder="Last name*"
                      value={formData.lastName}
                      name="lastName"
                      onChange={handleInputChange}
                      required
                    />
                    {errors.lastName && (
                      <div style={styleClass}>{errors.lastName}</div>
                    )}
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="email" className="form-label label-text">
                      Email Address
                    </label>
                    <input
                      type="email"
                      className="form-control input-outline"
                      // placeholder="Email address*"
                      value={formData.email}
                      name="email"
                      onChange={handleInputChange}
                      required
                      disabled
                    />
                    {errors.email && (
                      <div style={styleClass}>{errors.email}</div>
                    )}
                  </div>
                  <div className="col-md-6">
                    <label
                      htmlFor="phoneNumber"
                      className="form-label label-text"
                    >
                      Phone Number*
                    </label>
                    <input
                      type="tel"
                      maxLength="10"
                      className="form-control input-outline"
                      // placeholder="Phone Number*"
                      value={formData.phoneNumber}
                      name="phoneNumber"
                      onChange={handleInputChange}
                      onKeyPress={(e) => {
                        const regex = /^[0-9\b]+$/;
                        if (!regex.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      required
                    />
                    {errors.phoneNumber && (
                      <div style={styleClass}>{errors.phoneNumber}</div>
                    )}
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="DOB" className="form-label label-text">
                      Date of Birth*
                    </label>
                    <input
                      type="date"
                      className="form-control input-outline"
                      placeholder="Date of Birth*"
                      value={formData.DOB}
                      name="DOB"
                      onChange={handleInputChange}
                      pattern="(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)"
                      max="2100-12-31"
                      required
                    />
                    {errors.DOB && <div style={styleClass}>{errors.DOB}</div>}
                  </div>
                  <div className="col-md-6">
                    {formData.userType === 'organization' && (
                      <>
                        <label
                          htmlFor="company"
                          className="form-label label-text"
                        >
                          Company*
                        </label>
                        <input
                          type="text"
                          className="form-control input-outline"
                          // placeholder="Company*"
                          value={formData.company}
                          name="company"
                          onChange={handleInputChange}
                          required
                        />
                      </>
                    )}
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="password" className="label-text">
                      Password*
                    </label>
                    <div className="input-group ">
                      <input
                        type={passwordToggle === true ? 'password' : 'text'}
                        className="form-control input-outline"
                        // placeholder="Password*"
                        aria-describedby="basic-addon1"
                        value={formData.password}
                        name="password"
                        minLength={8}
                        maxLength={40}
                        onChange={handlePasswordChange}
                        required
                      />
                      <span className="input-group-text" id="basic-addon1">
                        {!passwordToggle ? (
                          <img
                            src="../../images/hide_password.png"
                            alt=""
                            onClick={() => setpasswordToggle(!passwordToggle)}
                          />
                        ) : (
                          <img
                            src="../../images/eye-open.png"
                            alt=""
                            onClick={() => setpasswordToggle(!passwordToggle)}
                          />
                        )}
                      </span>
                    </div>
                    {errors.password && (
                      <div style={styleClass}>{errors.password}</div>
                    )}
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="cpassword" className="label-text">
                      Confirm Password*
                    </label>
                    <div className="input-group ">
                      <input
                        type={
                          confirmPasswordToggle === true ? 'password' : 'text'
                        }
                        className="form-control input-outline"
                        // placeholder="Confirm Password*"
                        aria-describedby="basic-addon1"
                        name="confirmPassword"
                        value={formData.confirmPassword}
                        onChange={handlePasswordChange}
                        maxLength="40"
                        required
                      />
                      <span className="input-group-text" id="basic-addon1">
                        {!confirmPasswordToggle ? (
                          <img
                            src="../../images/hide_password.png"
                            alt=""
                            onClick={() =>
                              setConfirmPasswordToggle(!confirmPasswordToggle)
                            }
                          />
                        ) : (
                          <img
                            src="../../images/eye-open.png"
                            alt=""
                            onClick={() =>
                              setConfirmPasswordToggle(!confirmPasswordToggle)
                            }
                          />
                        )}
                      </span>
                    </div>
                    {errors.confirmPassword && (
                      <div style={styleClass}>{errors.confirmPassword}</div>
                    )}
                  </div>
                  <h4 className="address-details-1">
                    HOME ADDRESS* <span>(NO PO BOXES ALLOWED)</span>
                  </h4>
                  <div className="outer-form">
                    <div className="row">
                      <div className="col-md-12">
                        <label
                          htmlFor="address"
                          className="form-label label-text"
                        >
                          Street Address 1*
                        </label>
                        <input
                          type="address"
                          name="address"
                          className="form-control input-outline"
                          // placeholder="Street Address 1*"
                          value={formData.address}
                          onChange={handleInputChange}
                          required
                        />
                        {errors.address && (
                          <div style={styleClass}>{errors.address}</div>
                        )}
                      </div>
                      <div className="col-md-12">
                        <label
                          htmlFor="address2"
                          className="form-label label-text"
                        >
                          Street Address 2
                        </label>
                        <input
                          type="address"
                          name="address2"
                          className="form-control input-outline"
                          // placeholder="Street Address 2"
                          onChange={handleInputChange}
                          value={formData.OptionalAddress}
                        />
                      </div>
                      <div className="col-md-4">
                        <label
                          htmlFor="state_"
                          className="form-label label-text"
                        >
                          State
                        </label>
                        <select
                          id="state_"
                          className="form-select input-outline"
                          name="state"
                          value={formData.state}
                          onChange={handleInputChange}
                          required
                        >
                          <option value=""> Select State</option>
                          {statelist.map((item, index) => (
                            <option value={item} key={index}>
                              {item}
                            </option>
                          ))}
                        </select>
                        <p className="form-membership">
                          We do not offer memberships for those in the following
                          states: New York, New Jersey, Washington
                        </p>
                      </div>
                      <div className="col-md-4">
                        <label
                          htmlFor="inputCity"
                          className="form-label label-text"
                        >
                          City
                        </label>
                        <input
                          type="text"
                          className="form-control input-outline"
                          id="inputCity"
                          // placeholder="City"
                          name="city"
                          onChange={handleInputChange}
                          value={formData.city}
                          required
                        />
                      </div>

                      <div className="col-md-4">
                        <label
                          htmlFor="inputZip"
                          className="form-label label-text"
                        >
                          Postal/ZIP code
                        </label>
                        <input
                          type="text"
                          className="form-control input-outline"
                          id="inputZip"
                          // placeholder="Postal/ZIP code"
                          name="zipCode"
                          onChange={handleInputChange}
                          value={formData.zipCode}
                          maxLength="5"
                          onKeyPress={(e) => {
                            const regex = /^[0-9\b]+$/;
                            if (!regex.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <p className="bill-address list-bil">BILLING ADDRESS</p>
                  <div className="col-12">
                    <div className="put-filed-same">
                      <label
                        className="form-check-label"
                        htmlFor="flexCheckDefault"
                      >
                        Same as above?
                      </label>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={isSameAddress}
                          onChange={(e) => setIsSameAddress(e.target.checked)}
                          id="flexCheckDefault"
                        />
                      </div>
                    </div>
                  </div>
                  {!isSameAddress && (
                    <div className="outer-form">
                      <div className="row">
                        <div className="col-md-12">
                          <label
                            htmlFor="name"
                            className="form-label label-text"
                          >
                            Street Address 1*
                          </label>
                          <input
                            type="address"
                            name="billingAddress"
                            className="form-control input-outline"
                            // placeholder="Street Address 1*"
                            value={formData.billingAddress}
                            onChange={handleInputChange}
                            required
                          />
                          {errors.billingAddress && (
                            <div style={styleClass}>
                              {errors.billingAddress}
                            </div>
                          )}
                        </div>
                        <div className="col-md-12">
                          <label
                            htmlFor="name"
                            className="form-label label-text"
                          >
                            Street Address 2
                          </label>
                          <input
                            type="address"
                            name="billingOptionalAddress"
                            className="form-control input-outline"
                            // placeholder="Street Address 2"
                            onChange={handleInputChange}
                            value={formData.billingOptionalAddress}
                          />
                        </div>
                        <div className="col-md-4">
                          <label
                            htmlFor="inputState1"
                            className="form-label label-text"
                          >
                            State
                          </label>
                          <select
                            id="inputState1"
                            className="form-select input-outline"
                            name="billingState"
                            onChange={handleInputChange}
                            value={formData.billingState}
                            required
                          >
                            <option selected>Select State</option>
                            {statelist.map((item, index) => (
                              <option value={item} key={index}>
                                {item}
                              </option>
                            ))}
                          </select>
                          <p className="form-membership">
                            We do not offer memberships for those in the
                            following states: New York, New Jersey, Washington
                          </p>
                        </div>
                        <div className="col-md-4">
                          <label
                            htmlFor="inputCity1"
                            className="form-label label-text"
                          >
                            City
                          </label>
                          <input
                            type="text"
                            className="form-control input-outline"
                            id="inputCity1"
                            // placeholder="City"
                            name="billingCity"
                            onChange={handleInputChange}
                            value={formData.billingCity}
                            required
                          />
                        </div>

                        <div className="col-md-4">
                          <label
                            htmlFor="inputZip1"
                            className="form-label label-text"
                          >
                            Postal/ZIP code
                          </label>
                          <input
                            type="text"
                            className="form-control input-outline"
                            id="inputZip1"
                            // placeholder="Postal/ZIP code"
                            name="billingZipCode"
                            onChange={handleInputChange}
                            value={formData.billingZipCode}
                            maxLength="5"
                            onKeyPress={(e) => {
                              const regex = /^[0-9\b]+$/;
                              if (!regex.test(e.key)) {
                                e.preventDefault();
                              }
                            }}
                            required
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="button-back px-1">
                    <Link to="/joinform" className="back-text">
                      Back
                    </Link>
                    <button
                      className={`theme-button mobile-button-view ${
                        isLoading ? 'disable' : ''
                      }`}
                      type="button"
                      onClick={handleManualSubmit}
                    >
                      {isLoading ? (
                        <div className="d-flex justify-content-center ">
                          <TailSpin
                            height="30"
                            width="30"
                            color="#fff"
                            ariaLabel="tail-spin-loading"
                            radius="1"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                          />
                        </div>
                      ) : (
                        'Next'
                      )}
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div className="col-lg-4">
              <Bill
                Heading="PLAN SUMMARY"
                Yearly={selectedPlan ? selectedPlan?.name : 'Loading...'}
                Dollar={
                  selectedPlan
                    ? '$' + selectedPlan?.packages[0]?.package_prices[0]?.price
                    : 'Loading...'
                }
                PlanUser="Plan"
                Price={
                  selectedPlan
                    ? '$' + selectedPlan?.packages[0]?.package_prices[0]?.price
                    : 'Loading...'
                }
                Tax="Tax ()"
                TaxPrice="$0.00"
                Total="Total"
                TotalPrice={
                  selectedPlan
                    ? '$' + selectedPlan?.packages[0]?.package_prices[0]?.price
                    : 'Loading...'
                }
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
Information.propTypes = {
  handleInformation: PropTypes.func,
};

export default memo(Information);
