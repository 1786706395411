import React, { useEffect, useState } from 'react';
import MainLayout from '../Layout/MainLayout';
import CardItems from '../components/CardItems';
import { eventsApi } from '../utils/endpoint';
import { getApi } from '../utils/api';
import { ThreeDots } from 'react-loader-spinner';
import moment from 'moment';
import PackagePlan from '../components/PackagePlan';

const Events = () => {
  const [eventList, setEventList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getApi(eventsApi)
      .then((res) => {
        if (res?.data?.success) {
          setEventList(res?.data?.data);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, []);
  return (
    <>
      <MainLayout>
        <div className="about-banner event">
          <div className="about-outer">
            <h1 className="banner-heading">Shoot more when you get away.</h1>
            {/* <p>-Benjamin Franklin</p> */}
          </div>
        </div>
        <div className="liberty-outer liberty">
          <div className="container">
            <div className="liberty-text">
              <h4 className="sub-title-2">Choose Membership Plan</h4>
            </div>
          </div>
        </div>
        <PackagePlan />
        <div className="event-card-list d-none">
          <div className="container">
            <div className="row gx-2">
              <div className="event-user-man">
                <div className="com-12">
                  <div className="event-heading">
                    <h4>EVENTS</h4>
                    <p>
                      Looking for a comprehensive listing of upcoming activities
                      and happenings? Look no further; from engaging workshops
                      to exciting range activities, our events page offers a
                      diverse range of opportunities for you to explore and
                      participate in. Stay up-to-date with the latest events by
                      regularly visiting our website&apos;s dedicated events
                      section.
                    </p>
                  </div>
                </div>
              </div>
              <div
                className={isLoading ? 'event-dote padding-add' : 'event-dote'}
              >
                <h4 className="event-mane-heading">All Events</h4>

                {isLoading && (
                  <div className={isLoading ? 'loading-dote ' : ''}>
                    <ThreeDots
                      height="120"
                      width="120"
                      radius="9"
                      color="#000"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  </div>
                )}
                {!isLoading && (
                  <div className="col-md-4 mb-4">
                    {eventList?.map((item, index) => (
                      <CardItems
                        ImagesCard={
                          item.image ? item.image : '../../images/event-3.jpg'
                        }
                        CardHeading={item.title}
                        DateCard={`${moment(item.date).format('M/D/YY')} ${
                          item.location
                        }`}
                        CardText={item.description}
                        HrefLink={item.link}
                        key={index}
                      />
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </MainLayout>
    </>
  );
};

export default Events;
