import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { logoutUser } from '../utils/Storage';

const AccountHeader = () => {
  const [activeItem, setActiveItem] = useState('');

  useEffect(() => {
    const path = window.location.pathname;
    setActiveItem(path);
  }, []);

  const handleLogout = () => {
    logoutUser();
    window.location.replace('/');
  };

  return (
    <>
      <div className="navbar d-lg-block d-none">
        <div className="container-fluid px-4">
          <header>
            <Link to="/" className="logo">
              <img src="../../images/logo.svg" alt="" />
            </Link>
            <nav>
              <ul className="menu-bar account-menu">
                <li className={activeItem === '/overview' ? 'active' : ''}>
                  <Link to="/overview">Overview</Link>
                </li>

                <li
                  className={activeItem === '/account-coverage' ? 'active' : ''}
                >
                  <Link to="/account-coverage">coverage</Link>
                </li>
                <li
                  className={activeItem === '/account-billing' ? 'active' : ''}
                >
                  <Link to="/account-billing">Billing</Link>
                </li>
                <li className={activeItem === '/get-help-now' ? 'active' : ''}>
                  <Link
                    to="/get-help-now"
                    className="theme-button button-nav-account"
                  >
                    Get help now!
                  </Link>
                </li>
              </ul>
            </nav>
            <div className="dropdown btn-group dropstart">
              <Link
                className="account_log_in"
                to="/profile-preferences"
                role="button"
                id="dropdownMenuLink"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="fa fa-user-o" aria-hidden="true"></i>
              </Link>

              <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                <li>
                  <Link
                    className="dropdown-item border-list"
                    to="/profile-preferences"
                  >
                    Profile & Preferences
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" onClick={handleLogout}>
                    Log out
                  </Link>
                </li>
              </ul>
            </div>
            {/* <Link to="/profile-preferences" className="log-in account_log_in">
              <i className="fa fa-user-o" aria-hidden="true"></i>
            </Link> */}
          </header>
        </div>
      </div>
      <div className="navbar navbar-mobile-menu d-lg-none d-block">
        <div className="container-fluid">
          <header>
            <Link to="/" className="logo">
              <img src="../../images/logo.svg" alt="" />
            </Link>
            <nav>
              <input type="checkbox" id="click" />
              <label htmlFor="click" className="menu-btn">
                <i className="fa fa-align-right"></i>
              </label>
              <ul className="menu-bar">
                <li className={activeItem === '/overview' ? 'active' : ''}>
                  <Link to="/overview">Overview</Link>
                </li>
                <li
                  className={activeItem === '/account-coverage' ? 'active' : ''}
                >
                  <Link to="/account-coverage">coverage</Link>
                </li>
                <li
                  className={activeItem === '/account-billing' ? 'active' : ''}
                >
                  <Link to="/account-billing">Billing</Link>
                </li>

                <li
                  className={
                    activeItem === '/profile-preferences' ? 'active' : ''
                  }
                >
                  <Link to="/profile-preferences">profile & preferences</Link>
                </li>
                <li>
                  <Link to="" onClick={handleLogout}>
                    Log out
                  </Link>
                </li>
                <li className={activeItem === '/get-help-now' ? 'active' : ''}>
                  <Link
                    to="/get-help-now"
                    className="theme-button button-nav-account text-center mx-3 p-2"
                  >
                    get help now!
                  </Link>
                </li>
              </ul>
            </nav>
          </header>
        </div>
      </div>
    </>
  );
};

export default AccountHeader;
