import React, { useEffect } from 'react';
import { useState } from 'react';
import { handleTabAction } from '../../utils/helpers';
import PropTypes from 'prop-types';

const Questionnaire = ({ handleQuestionnaire, handleTabChange }) => {
  const [question1, setQuestion1] = useState('No');
  const [question2, setQuestion2] = useState('No');
  const [isNextDisabled, setIsNextDisabled] = useState(false);
  const [showError, setShowError] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === 'myradio-q1') {
      setQuestion1(value);
    } else if (name === 'myradio-q2') {
      setQuestion2(value);
    }
  };

  const handleNext = () => {
    handleTabAction('questionnaire', 'review');
    handleQuestionnaire('review');
  };

  const handleBack = () => {
    handleTabChange('billing');
    handleTabAction('questionnaire', 'billing');
  };

  useEffect(() => {
    var element = document.getElementsByClassName('next-btn')[0];
    if (question1 === 'Yes' || question2 === 'Yes') {
      setIsNextDisabled(true);
      element.classList.add('disable');
      setShowError(true);
    } else {
      setIsNextDisabled(false);
      setShowError(false);
      element.classList.remove('disable');
    }
  }, [question1, question2]);

  return (
    <>
      {console.log(isNextDisabled)}
      {showError && (
        <div className="alert_inner error-background">
          <span>Sorry...You are not eligible.</span>
          <a
            className="close__alert"
            onClick={() => {
              setShowError(false);
            }}
          >
            ×
          </a>
        </div>
      )}
      <div className="join-form-user option-user">
        <div className="container-fluid">
          <div className="row">
            <div className="outer-event-policy mt-4">
              <h4>
                Are you under indictment or information in any court for a
                felony, or any other crime for which the judge could imprison
                you for more than one year, or are you a current member of the
                military who has been charged with violation(s) of the Uniform
                Code of Military Justice and whose charge(s) have been referred
                to a general court-martial?
              </h4>
              <div className="radio-button">
                <input
                  className="radio-input"
                  type="radio"
                  name="myradio-q1"
                  id="myradio33"
                  value="Yes"
                  checked={question1 === 'Yes'}
                  onChange={handleInputChange}
                />
                <label htmlFor="myradio33" className="radio__label">
                  <p>Yes</p>
                </label>
                <input
                  className="radio-input"
                  type="radio"
                  name="myradio-q1"
                  id="myradio34"
                  value="No"
                  checked={question1 === 'No'}
                  onChange={handleInputChange}
                />
                <label htmlFor="myradio34" className="radio__label">
                  <p>No</p>
                </label>
              </div>
            </div>
            <div className="outer-event-policy">
              <h4>
                Have you ever been adjudicated as a mental defective OR have you
                ever been committed to a mental institution?
              </h4>
              <div className="radio-button">
                <input
                  className="radio-input"
                  type="radio"
                  name="myradio-q2"
                  id="myradio-3"
                  value="Yes"
                  checked={question2 === 'Yes'}
                  onChange={handleInputChange}
                />
                <label htmlFor="myradio-3" className="radio__label">
                  <p>Yes</p>
                </label>
                <input
                  className="radio-input"
                  type="radio"
                  name="myradio-q2"
                  id="myradioTwo"
                  value="No"
                  checked={question2 === 'No'}
                  onChange={handleInputChange}
                />
                <label htmlFor="myradioTwo" className="radio__label">
                  <p>No</p>
                </label>
              </div>
            </div>

            <div className="button-back">
              <a onClick={handleBack} className="back-text">
                Back
              </a>
              <button
                type="submit"
                className="theme-button next-btn disable mobile-button-view"
                onClick={handleNext}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
Questionnaire.propTypes = {
  handleQuestionnaire: PropTypes.func,
  handleTabChange: PropTypes.func,
};

export default Questionnaire;
