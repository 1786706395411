import React from 'react';
import MainLayout from '../Layout/MainLayout';
import BlogCard from '../components/BlogCard';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link } from 'react-router-dom';
const Education = () => {
  const options = {
    margin: 5,
    responsiveClass: true,
    nav: true,
    stagePadding: 15,
    // autoplay: true,
    navText: [
      "<i class='fa fa-chevron-left'></i>",
      "<i class='fa fa-chevron-right'></i>",
    ],
    // navText: ["&#x27next&#x27", "&#x27prev&#x27"],
    // smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      900: {
        items: 2,
      },
    },
  };

  return (
    <>
      <MainLayout>
        <div className="about-banner banner-self">
          <div className="about-outer">
            <h1 className="banner-heading">Explore + Shoot + Fun</h1>
          </div>
        </div>
        <div className="responsibly mt-4 d-none">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="responsibly-user-text p-0">
                  <h3 className="font-change">
                    COVERAGES offered by Right To Bear:
                  </h3>
                  <div className="list-pointer">
                    <ul>
                      <li>
                        <img
                          src="../../images/shield_svg.svg"
                          alt=""
                          className="img-fluid"
                        />
                        <p>
                          Civil Defense coverage allows you to choose your own
                          attorney in the event you are sued after a
                          self-defense incident.
                        </p>
                      </li>
                      <li>
                        <img
                          src="../../images/shield_svg.svg"
                          alt=""
                          className="img-fluid list-image"
                        />

                        <p>
                          Criminal Defense coverage provides legal support in
                          the event you are criminally charged after a
                          self-defense incident. (You choose your own attorney)
                        </p>
                      </li>
                      <li>
                        <img
                          src="../../images/shield_svg.svg"
                          alt=""
                          className="img-fluid list-image"
                        />

                        <p>
                          Multi state coverage offers you protection in 50
                          states. Residents of New York, New Jersey, and
                          Washington do not qualify for our membership.
                        </p>
                      </li>
                      <li>
                        <img
                          src="../../images/shield_svg.svg"
                          alt=""
                          className="img-fluid"
                        />
                        <p>
                          Expert Witness coverage will provide an “expert
                          witness” to testify on your behalf
                        </p>
                      </li>
                      <li>
                        <img
                          src="../../images/shield_svg.svg"
                          alt=""
                          className="img-fluid"
                        />
                        <p>
                          Psychological support provides coverage for counseling
                          sessions due to stress/trauma experienced from a
                          self-defense incident.
                        </p>
                      </li>
                      <li>
                        <img
                          src="../../images/shield_svg.svg"
                          alt=""
                          className="img-fluid"
                        />
                        <p>
                          Bail Bond Reimbursement provides money for bail up to
                          $100,000.
                        </p>
                      </li>
                      <li>
                        <img
                          src="../../images/shield_svg.svg"
                          alt=""
                          className="img-fluid"
                        />
                        <p>
                          Additional Associate Coverage extends coverage to a
                          spouse or household partner.
                        </p>
                      </li>
                      <li>
                        <img
                          src="../../images/shield_svg.svg"
                          alt=""
                          className="img-fluid"
                        />
                        <p>
                          Gun replacement – We will replace your gun if taken
                          after a self-defense incident.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bear-facts d-none">
          <div className="container">
            <div className="row">
              <h3 className="sub-title">BEAR FACTS</h3>
              <div className="col-md-6">
                <div className="facts-bear-heading">
                  <img src="../../images/fact1.webp" alt="" />
                  <h4>BEAR FACT 1</h4>
                  <p className="sub-hea-edc">Increase in Crime</p>
                  <p>
                    In 2020, the murder rate rose by nearly 30%, the largest
                    increase on record. There were about 21,500 murders, or 6.5
                    per 100,000 people. Aggravated assault, the most common form
                    of violent crime, rose 12% in the United States.
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="facts-bear-heading">
                  <img src="../../images/fact2.webp" alt="" />
                  <h4>BEAR FACT 2</h4>
                  <p className="sub-hea-edc">Home Invasions</p>
                  <p>
                    38% of assaults occur during home invasions. Over 2,000,000
                    homes will experience a break-in or burglary this year There
                    are over 4,500 home burglaries per day in the United States.
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="facts-bear-heading">
                  <img src="../../images/fact3.jpg" alt="" />
                  <h4>BEAR FACT 3</h4>
                  <p className="sub-hea-edc">Police Response Time</p>
                  <p>
                    Police response times have slowed to an average of 10
                    minutes, while 911 call centers are handling 240 million
                    calls a year, leading to an overworked police force. Law
                    abiding citizens are faced with the very real possibility of
                    having to defend themselves more than ever before.
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="facts-bear-heading">
                  <img src="../../images/fact4.webp" alt="" />
                  <h4>BEAR FACT 4</h4>
                  <p className="sub-hea-edc">
                    Home/Umbrella Policies Will Not Defend You
                  </p>
                  <p>
                    Home insurance typically excludes coverage for acts of
                    self-defense. If you own a firearm, you need self-defense
                    liability coverage details on availability, style, or even
                    provide a review.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="post-blog card-disable d-none">
          <div className="container">
            <div className="row g-3">
              <div className="blog-view">
                <h3 className="blog-heading">Blog posts</h3>
                <a href="#">View all</a>
              </div>
              <div className="col-md-4">
                <BlogCard
                  BlogImage="../../images/custom_resized.webp"
                  BlogHeading=" Qualities of a Defensive Handgun"
                  BlogTime="June 23, 2023"
                  BlogTitle="Not all handguns are designed with self defense in mind and some that are aren’t particularly well suited for it. Knowing what qualities to look for in a defensive handgun..."
                  href="/qualities-defensive-handgun"
                />
              </div>

              <div className="col-md-4">
                <BlogCard
                  BlogImage="../../images/blog-1.webp"
                  BlogHeading="So You Bought a Handgun for Personal Protection"
                  BlogTime="MAY 5 2023"
                  BlogTitle="Owning a handgun for personal protection doesn’t mean one is adequately prepared to use it for that purpose. Just like owning a car doesn’t mean one is ready to start..."
                  href="/right-to-bear-personal"
                />
              </div>
              <div className="col-md-4">
                <BlogCard
                  BlogImage="../../images/blog-2.webp"
                  BlogHeading=" To Open Carry or Concealed Carry?"
                  BlogTime="MARCH 17 2023"
                  BlogTitle="There are a lot of opinions when it comes to the debate between open carry versus concealed carry. Heck, I have plenty of my own. However, I think the decision..."
                  href="/open-carry"
                />
              </div>

              <div className="text-center">
                <a href="/righttoblog" className="button-black">
                  view all
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="post-blog theme-carousel d-none">
          <div className="container-fluid">
            <div className="blog-view">
              <h3 className="blog-heading">Blog posts</h3>
              <Link to="/righttoblog">View all</Link>
            </div>
            <OwlCarousel
              style={{ zIndex: '0' }}
              className="slider-items owl-carousel"
              {...options}
            >
              <BlogCard
                BlogImage="../../images/custom_resized.webp"
                BlogHeading=" Qualities of a Defensive Handgun"
                BlogTime="June 23, 2023"
                BlogTitle="Not all handguns are designed with self defense in mind and some that are aren’t particularly well suited for it. Knowing what qualities to look for in a defensive handgun..."
                href="/qualities-defensive-handgun"
              />
              <BlogCard
                BlogImage="../../images/blog-1.webp"
                BlogHeading="So You Bought a Handgun for Personal Protection"
                BlogTime="MAY 5 2023"
                BlogTitle="Owning a handgun for personal protection doesn’t mean one is adequately prepared to use it for that purpose. Just like owning a car doesn’t mean one is ready to start..."
                href="/right-to-bear-personal"
              />
              <BlogCard
                BlogImage="../../images/blog-2.webp"
                BlogHeading=" To Open Carry or Concealed Carry?"
                BlogTime="MARCH 17 2023"
                BlogTitle="There are a lot of opinions when it comes to the debate between open carry versus concealed carry. Heck, I have plenty of my own. However, I think the decision..."
                href="/open-carry"
              />
            </OwlCarousel>
          </div>
          <div className="text-center d-none d-lg-block">
            <a href="/righttoblog" className="button-black">
              view all
            </a>
          </div>
        </div>
        <div className="responsibly mt-0 mb-5 p-0 d-none">
          <div className="container">
            <div className="responsibly-user-text">
              <h3 className="blog-list-heading ps-md-3">
                The primary benefit of joining Right To Bear, is that we provide
                legal defense for acts of self-defense that are generally not
                covered by homeowners/renters insurance policies.
              </h3>
              <div className="list-pointer ps-md-3">
                <ul>
                  <li>
                    <img
                      src="../../images/shield_svg.svg"
                      alt=""
                      className="img-fluid"
                    />
                    <p>Unlimited Civil / Criminal Defense protection</p>
                  </li>
                  <li>
                    <img
                      src="../../images/shield_svg.svg"
                      alt=""
                      className="img-fluid"
                    />
                    <p>Expert Witness Included</p>
                  </li>
                  <li>
                    <img
                      src="../../images/shield_svg.svg"
                      alt=""
                      className="img-fluid list-image"
                    />
                    <p>
                      We offer additional coverages where you need them most.
                      Including Additional Associate Defense, Bail Bond Coverage
                      up to $100,000, Minor Children Defense, and Multi-State
                      Defense.
                    </p>
                  </li>
                  <li>
                    <img
                      src="../../images/shield_svg.svg"
                      alt=""
                      className="img-fluid"
                    />
                    <p>Spousal Coverage*</p>
                  </li>
                  <li>
                    <img
                      src="../../images/shield_svg.svg"
                      alt=""
                      className="img-fluid"
                    />
                    <p>Psychological Support</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="join-right d-none">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-4 g-0">
                <img
                  src="../../images/iStock.webp"
                  alt=""
                  className="img-fluid istock-image"
                />
              </div>
              <div className="col-md-8 g-0">
                <div className="">
                  <div className="join-file">
                    <h3 className="sub-title">
                      MAJOR BENEFITS OF JOINING RIGHT TO BEAR
                    </h3>
                    <div className="list-pointer">
                      <ul>
                        <li>
                          <img
                            src="../../images/shield_svg.svg"
                            alt=""
                            className="img-fluid list-image"
                          />
                          <p>
                            Right to Bear is NOT a reimbursement policy like
                            many of our competitors. We pay everything UPFRONT.
                          </p>
                        </li>
                        <li>
                          <img
                            src="../../images/shield_svg.svg"
                            alt=""
                            className="img-fluid"
                          />
                          <p>
                            We offer UNLIMITED DEFENSE for both civil and
                            criminal cases.
                          </p>
                        </li>
                        <li>
                          <img
                            src="../../images/shield_svg.svg"
                            alt=""
                            className="img-fluid list-image"
                          />
                          <p>
                            Right to Bear offers several additional coverage
                            options to ensure you are fully protected in an act
                            of self-defense.
                          </p>
                        </li>
                        <li>
                          <img
                            src="../../images/shield_svg.svg"
                            alt=""
                            className="img-fluid"
                          />
                          <p>Covers your defense in a civil suit*</p>
                        </li>
                        <li>
                          <img
                            src="../../images/shield_svg.svg"
                            alt=""
                            className="img-fluid list-image"
                          />
                          <p>
                            Protects you for using your legally owned firearm,
                            with or without a concealed carry permit*
                          </p>
                        </li>
                        <li>
                          <img
                            src="../../images/shield_svg.svg"
                            alt=""
                            className="img-fluid list-image"
                          />
                          <p>
                            The best way to avoid a self-defense incident is to
                            make sure your weapons are properly stored and
                            handled, but additional coverage could be a
                            financial lifesaver if the unthinkable were to
                            happen.
                          </p>
                        </li>
                      </ul>
                      <p className="text-liability">
                        <strong>
                          Right to Bear provides self-defense liability
                          protection to gun people by gun people. Home &
                          Umbrella policies DO NOT provide self-defense
                          liability.
                        </strong>
                      </p>
                    </div>
                  </div>
                  <img
                    src="../../images/line.svg"
                    alt="line"
                    className="img-fluid ps-3"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="liberty-outer">
          <div className="container">
            <div className="liberty-text">
              <h4 className="sub-title-2">Why Join?</h4>
              <p className="bear-text">
                Make the most of the Caliber Card benefits with insider range
                guides, upcoming events, and one-of-a-kind shooting experiences
                from the finest ranges in the United States.
              </p>
              <p className="bear-text">
                Every shooting sports enthusiast agrees that &apos;any day on
                the range is a good day.&apos; The Caliber Car is there so that
                even when you travel, &apos;any day is a range day!&apos;.
                Whether on the road for business or vacationing in another great
                city, the Caliber Card from The Range Network is there as you
                travel. Also, your membership helps support your local
                Participating Range and the community they serve. We put money
                into network ranges to help improve facilities and access to the
                shooting sport we all love! This helps ensure you will continue
                to have a place to go shoot now, and in the future.
              </p>
            </div>
          </div>
        </div>
        <div className="member-list">
          <div className="container">
            <div className="row">
              <h5 className="sub-title-2">
                Benefits of being Caliber Card Member:
              </h5>
              <ul>
                <li>Gain access to Network Ranges around the country</li>
                <li>Support the safety and security of our communities</li>
                <li>
                  Provide skills progression programs and training for local
                  youth
                </li>
              </ul>
            </div>
          </div>
        </div>
      </MainLayout>
    </>
  );
};

export default Education;
