import React from 'react';
import MainLayout from '../Layout/MainLayout';
import { Link } from 'react-router-dom';

const About = () => {
  return (
    <>
      <MainLayout>
        <div className="about-banner">
          <div className="about-outer">
            <h1 className="banner-heading">
              Our goal is to make your trips unforgettable.
            </h1>
          </div>
        </div>
        <div className="liberty-outer">
          <div className="container">
            <div className="liberty-text">
              <h4 className="sub-title-2">How it Works?</h4>
              <p className="bear-text">
                If you&lsquo;re going to travel, why not make it a point to
                practice your aim. The Caliber Card is your passport to
                exploring the premier ranges around the country. We are bringing
                the shooting sports community together like never before. Ranges
                and shooting sports enthusiasts from across the country connect
                to focus on one thing, &quot;Experience shooting like never
                before!&quot;
              </p>
              <p className="bear-text">
                Don&lsquo;t miss opportunities to visit amazing shooting ranges,
                support businesses who protect our communities, and hang out
                with like-minded people who share a common affinity for the
                shooting sports.
              </p>
            </div>
            <div className="percentage">
              <img src="../../images/line.svg" alt="" className="img-fluid" />
            </div>
            <div className="right-status">
              <div className="row">
                <div className="col-md-4">
                  <div className="status-right-bear">
                    <h4>Join</h4>
                    <p>
                      The most important and yet, the simplest step – join! In
                      an instant, you will have access to a network of premier
                      ranges around the country at a special discount and with
                      special offers.
                    </p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="status-right-bear px-md-3">
                    <h4>Explore</h4>
                    <p>
                      The Caliber Card® connects you with top ranges across the
                      country. Every time you travel you have access to a great
                      variety of experiences, instructors, and benefits. You can
                      browse the city of your destination and discover the
                      perfect way to enjoy your Membership.
                    </p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="status-right-bear">
                    <h4>Experience</h4>
                    <p>
                      Every Caliber Card Member is greeted by the happy faces of
                      the range staff. From special events to “share-worthy”
                      shooting experiences, there’s no limit to the excitement
                      of being on a new range!
                    </p>
                  </div>
                </div>
                <div className="join-btn d-none">
                  <Link
                    to="/coverage"
                    className="theme-button login-button py-4"
                  >
                    join now
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="responsible-user-gun d-none">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 g-0">
                <img
                  src="../../images/aboutSection.webp"
                  alt="aboutSection"
                  className="w-100"
                />
              </div>
              <div className="col-md-6 g-0">
                <div className="gun-man">
                  <div className="user-gun-text">
                    <h4 className="sub-title">
                      RIGHT TO BEAR IS SELF DEFENSE PROTECTION FOR THE
                      RESPONSIBLE GUN OWNER
                    </h4>
                    <p>
                      It provides the BEST value for coverage and affordability
                      in its class
                    </p>
                    <p>
                      Along with your membership, you will receive our “Bear
                      Facts” weekly educational feature which will always give
                      the “Bear Facts” of situational awareness, trauma
                      avoidance, and simply what and what not to do if you have
                      faced a self-defense situation with your firearm
                    </p>
                    <p>
                      We believe in protecting freedom, protecting family, and
                      protecting YOU. Let us secure your piece of mind so you
                      can stand your ground with Right To Bear!
                    </p>
                    <Link
                      to="/carryresponsibly"
                      className="button-white mobile-width"
                    >
                      learn more
                    </Link>
                  </div>
                  <div className="rifle-percentage">
                    <img
                      src="../../images/line.svg"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="talk-about d-none">
          <div className="container">
            <div className="row">
              <div className="brand-talk">
                <h5 className="title-text">ADD SUBTITLE</h5>
                <h3>Talk about your brand</h3>
                <p>
                  Share information about your brand with your customers.
                  Describe a product, make announcements, or welcome customers
                  to your store.
                </p>
                <button
                  type="submit"
                  className="theme-button disable login-button"
                >
                  button label
                </button>
              </div>
            </div>
          </div>
        </div>
      </MainLayout>
    </>
  );
};

export default About;
