import React from 'react';
import MainLayout from '../Layout/MainLayout';
import { Link } from 'react-router-dom';

const Congratulations = () => {
  return (
    <>
      <MainLayout>
        <div className="congratulations">
          <div className="container">
            <div className="con-membership">
              <h4 className="sub-title-2">
                Range Membership Application - The Range Network
              </h4>
              <img src="../images/card-black.png" alt="" />
              <h2 className="sub-title-2">CONGRATULATIONS!</h2>
              <p className="bear-text">
                You are on your way to becoming a Participating Network Range!
              </p>
              <p className="bear-text multiple">
                If you have multiple ranges you will need to fill out a
                registration for each location individually. Each location will
                need a unique email address. Please use a contact person&lsquo;s
                email, NOT a general email. This email will not be published or
                shared publicly.
              </p>
            </div>
            <div className="mem-plan">
              <div className="row">
                <div className="col-md-12">
                  <div className="mm-p">
                    <h4 className="sub-title-2 text-center mb-4">
                      Membership Plan
                    </h4>
                    <h5 className="bear-text">Network Ranges</h5>
                    <div className="form-check mb-4">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault2"
                        checked
                      />
                      <label
                        className="form-check-label bear-text"
                        htmlFor="flexRadioDefault2"
                      >
                        Participating
                      </label>
                      <p className="bear-text">$0.00/mo USD</p>
                    </div>
                    <div className="">
                      <label htmlFor="name" className="label-text">
                        Discount Code:
                      </label>
                      <input
                        type="text"
                        className="input-outline"
                        name="code"
                        placeholder="Discount Code:"
                      />
                    </div>
                    <p className="bear-text pt-4 text-md-center">
                      If you have been given a Discount Code please enter it
                      above. (EX: TRN1234)
                    </p>
                    <div className="text-center pt-5">
                      <Link
                        to="/membership-application"
                        className="theme-button"
                      >
                        Click Here to Register
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </MainLayout>
    </>
  );
};

export default Congratulations;
