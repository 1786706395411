import React, { useRef, useState } from 'react';
import MainLayout from '../Layout/MainLayout';
import { postApi } from '../utils/api';
import { contact } from '../utils/endpoint';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link } from 'react-router-dom';
import PackagePlan from '../components/PackagePlan';

const Index = () => {
  const options = {
    margin: 5,
    responsiveClass: true,
    nav: true,
    smartSpeed: 1000,
    responsive: {
      100: {
        items: 1,
      },
      700: {
        items: 1,
      },
      900: {
        items: 1,
      },
    },
  };
  const [contactUsForm, setContactUsForm] = useState({
    name: '',
    email: '',
    phoneNumber: '',
    comment: '',
  });
  const [isResponse, setIsResponse] = useState(false);

  const [responseMessage, setResponseMessage] = useState('');

  const formRef = useRef(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formdata = new FormData();
    formdata.append('name', contactUsForm.name);
    formdata.append('email', contactUsForm.email);
    formdata.append('phone', contactUsForm.phoneNumber);
    formdata.append('contact', contactUsForm.comment);
    await postApi(contact, formdata)
      .then((res) => {
        if (res.data.success) {
          setContactUsForm({
            name: '',
            email: '',
            phoneNumber: '',
            comment: '',
          });
          setIsResponse(true);
          setResponseMessage(
            "Thanks for contacting us. We'll get back to you as soon as possible."
          );
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setContactUsForm((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleManualSubmit = async (e) => {
    e.preventDefault();
    const form = formRef.current;
    if (form.checkValidity()) {
      await handleSubmit(e);
    } else {
      form.reportValidity();
    }
  };
  return (
    <>
      <MainLayout>
        <div className="post-blog post-log">
          <div className="container-fluid px-0">
            <OwlCarousel className="slider-items owl-carousel" {...options}>
              <div className=" new-dd">
                <div className="banner-outre">
                  <h1 className="banner-heading">
                    If you love the journey, you’re gonna love the destination.
                  </h1>

                  <Link
                    to="/join-now"
                    className="button-size theme-button mt-3"
                  >
                    JOIN NOW
                  </Link>
                </div>
              </div>
              <div className=" new-dd">
                <div className="banner-outre">
                  <h1 className="banner-heading">
                    On-the-range exhilaration, state-of-the-art excitement
                  </h1>
                  <Link
                    to="/join-now"
                    className="button-size theme-button mt-3"
                  >
                    JOIN NOW
                  </Link>
                </div>
              </div>
              <div className=" new-dd">
                <div className="banner-outre">
                  <h1 className="banner-heading">
                    Choose your city. Choose your fun.
                  </h1>
                  <Link
                    to="/join-now"
                    className="button-size theme-button mt-3"
                  >
                    JOIN NOW
                  </Link>
                </div>
              </div>
            </OwlCarousel>
          </div>
        </div>
        <div className="liberty-outer liberty">
          <div className="container">
            <div className="liberty-text">
              <h4 className="sub-title-2">What is the Caliber Card?</h4>
              <p className="bear-text pb-5">
                Enjoy the thrill of the range wherever you go with the exclusive
                benefits of the Caliber Club! The nationwide Caliber Card
                Membership, brought to you by the Range Network, enables you to
                visit any participating retail gun range in the United States
                through membership at your “home” range. Your home location is
                the facility where you enrolled as a member or where it is part
                of your membership dues.
              </p>
              <h4 className="sub-title-2">
                Get Offers and Updates to Your Inbox
              </h4>
              <p className="bear-text">
                Caliber Card Members get access to exclusive offers and access
                to unique events at each of our participating partner Ranges.
                Walking into a partner range is like entering an exciting world
                of new experiences, fun and community.
              </p>
              <div className="sub-em">
                <input
                  type="email"
                  className="input-outline mb-0"
                  placeholder="name@example.com"
                />
                <button className="theme-button email-button">Subscribe</button>
              </div>
            </div>
          </div>
        </div>
        <h4 className="sub-title-2 text-center pt-4">Choose Membership Plan</h4>
        <PackagePlan />
        <div className="responsibly d-none">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-md-6 g-0  order-md-2">
                <div className="gun-images ">
                  <img src="../../images/guns.jpg" alt="" className="w-100" />
                </div>
              </div>
              <div className="col-md-6 order-md-1">
                <div className="responsibly-user-text">
                  <h3 className="sub-title">
                    PROTECT YOUR FAMILY, RESPONSIBLY.
                  </h3>
                  <p className="sub-text-user">
                    Right To Bear offers Elite protection along with many other
                    great benefits. Our plans offer more than just legal
                    reimbursement to secure your peace of mind.
                  </p>

                  <div className="list-pointer">
                    <ul>
                      <li>
                        <img
                          src="../../images/shield_svg.svg"
                          alt=""
                          className="img-fluid "
                        />
                        <p>Civil Defense</p>
                      </li>
                      <li>
                        <img
                          src="../../images/shield_svg.svg"
                          alt=""
                          className="img-fluid "
                        />
                        <p>Criminal Defenses</p>
                      </li>
                      <li className="d-flex align-items-start">
                        <img
                          src="../../images/shield_svg.svg"
                          alt=""
                          className="img-fluid list-image pt-2"
                        />
                        <p>
                          All states in the United States except New York, New
                          Jersey and Washington,
                        </p>
                      </li>
                      <li>
                        <img
                          src="../../images/shield_svg.svg"
                          alt=""
                          className="img-fluid "
                        />
                        <p>Psychology Support</p>
                      </li>
                      <li>
                        <img
                          src="../../images/shield_svg.svg"
                          alt=""
                          className="img-fluid "
                        />
                        <p>Expert Witness Defense</p>
                      </li>
                      <li>
                        <img
                          src="../../images/shield_svg.svg"
                          alt=""
                          className="img-fluid "
                        />
                        <p>Gun Replacement</p>
                      </li>
                      <li>
                        <img
                          src="../../images/shield_svg.svg"
                          alt=""
                          className="img-fluid "
                        />
                        <p>Accidental Discharge</p>
                      </li>
                      <li>
                        <img
                          src="../../images/shield_svg.svg"
                          alt=""
                          className="img-fluid "
                        />
                        <p>Self Defense Training Videos</p>
                      </li>
                      <li>
                        <img
                          src="../../images/shield_svg.svg"
                          alt=""
                          className="img-fluid "
                        />
                        <p>Discount to Industry Partners</p>
                      </li>
                      <li>
                        <img
                          src="../../images/shield_svg.svg"
                          alt=""
                          className="img-fluid "
                        />
                        <p>Spousal Coverage *</p>
                      </li>
                      <li>
                        <img
                          src="../../images/shield_svg.svg"
                          alt=""
                          className="img-fluid "
                        />
                        <p>Minor Children Defense *</p>
                      </li>
                      <li>
                        <img
                          src="../../images/shield_svg.svg"
                          alt=""
                          className="img-fluid "
                        />
                        <p>Bail Bond Coverage ($100,000) max *</p>
                      </li>
                      <li>
                        <img
                          src="../../images/shield_svg.svg"
                          alt=""
                          className="img-fluid "
                        />
                        <p>24/7 Attorney Answered Hotline</p>
                      </li>
                    </ul>
                    <a
                      href="/coverage"
                      className="theme-button button-white mobile-width"
                      style={{
                        padding: '18px 80px',
                        border: '1px solid #b6632f',
                      }}
                    >
                      JOIN NOW
                    </a>
                    <p className="list-available">
                      *Available as an optional add-on
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="shopify-section-template d-none">
          <div className="container">
            <div className="row">
              <div className="template">
                <div className="shopify mb-4">
                  <h3 className="section-heading">STILL HAVE QUESTIONS?</h3>
                  <p className="email-text">
                    Contact our customer service team at{' '}
                    <a
                      href="mailto:info@protectwithbear.com"
                      target="_blank"
                      rel="noreferrer"
                    >
                      info@protectwithbear.com
                    </a>
                  </p>
                  {isResponse && (
                    <>
                      <div className="check-img-and-text">
                        <img src="../images/check_green.svg" alt="" />
                        &nbsp;
                        <p>{responseMessage}</p>
                      </div>
                    </>
                  )}
                </div>

                <form id="earlyAccessForm" ref={formRef}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-floating">
                        <input
                          type="name"
                          name="name"
                          className="form-control form-input"
                          placeholder="Name"
                          value={contactUsForm.name}
                          onChange={handleInputChange}
                          required
                        />
                        <label htmlFor="floatingInput">Name</label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating">
                        <input
                          type="email"
                          name="email"
                          className="form-control form-input"
                          placeholder="email"
                          value={contactUsForm.email}
                          onChange={handleInputChange}
                          required
                        />
                        <label>Email*</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-floating">
                        <input
                          className="form-control form-input"
                          placeholder="Phone Number"
                          name="phoneNumber"
                          type="tel"
                          maxLength="10"
                          onKeyPress={(e) => {
                            const regex = /^[0-9\b]+$/;
                            if (!regex.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                          value={contactUsForm.phoneNumber}
                          onChange={handleInputChange}
                        />
                        <label>Phone Number</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-floating">
                        <textarea
                          className="form-control form-input"
                          placeholder="Leave a comment here"
                          style={{ height: '100px' }}
                          name="comment"
                          value={contactUsForm.comment}
                          onChange={handleInputChange}
                        ></textarea>
                        <label htmlFor="floatingTextarea2">Comments</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <button
                        form="earlyAccessForm"
                        className="from-button"
                        onClick={handleManualSubmit}
                      >
                        Submit
                      </button>
                    </div>
                    <p className="form-text-last">
                      By completing this form, I have read and acknowledged the
                      Privacy Statement and agree that Right to Bear may contact
                      me at the email address or phone number above
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </MainLayout>
    </>
  );
};

export default Index;
