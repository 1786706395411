import React from 'react';
import PropTypes from 'prop-types';

const Accordion = (props) => {
  return (
    <>
      <div className="accordion" id="accordionPanelsStayOpenExample">
        <div className="faq-question">
          <div className="container">
            <div className="row">
              <h3 className="sub-title pb-4">{props.heading}</h3>
              <div className="accordion-item">
                <h2 className="accordion-header" id="panelsStayOpen-headingOne">
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#panelsStayOpen-collapseOne"
                    aria-expanded="true"
                    aria-controls="panelsStayOpen-collapseOne"
                  >
                    What are the claims hotline hours?
                  </button>
                </h2>
                <div
                  id="panelsStayOpen-collapseOne"
                  className="accordion-collapse collapse show"
                  aria-labelledby="panelsStayOpen-headingOne"
                >
                  <div className="accordion-body">
                    <p className="body-title">
                      Available to you 24 hours a day, 7 days a week
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion" id="accordionExample">
                <div className="accordion-item">
                  <div className="accordion-header" id="headingOne1">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne1"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      Does the coverage extend to all legal firearms?
                    </button>
                  </div>
                  <div
                    id="collapseOne1"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingOne1"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <p className="body-title">
                        Lawful acts of self-defense are all covered under your
                        policy regardless of the legal firearm used
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="accordion" id="accordionExample">
                <div className="accordion-item">
                  <div className="accordion-header" id="headingOne2">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne2"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      What is included with this association?
                    </button>
                  </div>
                  <div
                    id="collapseOne2"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingOne2"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <div className="font-list-civil">
                        <ul>
                          <li>
                            Unlimited Civil & Criminal Defense. (No Limits, No
                            Reimbursement, 24/7 Coverage)
                          </li>
                          <li>Psychological support Included.</li>
                          <li>Expert Witness coverage Included.</li>
                          <li>
                            Firearm & Self-Defense Training & Education
                            Included.
                          </li>
                          <li>Quarterly Firearms Newsletter Included.</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="accordion" id="accordionExample1">
                <div className="accordion-item">
                  <div className="accordion-header" id="headingOne4">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne4"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      Why do I need to become an associate?
                    </button>
                  </div>
                  <div
                    id="collapseOne4"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingOne4"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <div className="font-list-civil">
                        <ul>
                          <li>a. Police staffs are thin</li>
                          <li>
                            b. Police response times are down because of lack of
                            resources – requires you to think quick
                          </li>
                          <li>c. Defense cost can be over 6 figures</li>
                          <li>
                            d. You buy the gun for protection. This association
                            is your extra line of defense.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="accordion" id="accordionExample1">
                <div className="accordion-item">
                  <div className="accordion-header " id="headingOne5">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne5"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      If I shoot someone defending myself what should be the
                      first thing I do?
                    </button>
                  </div>
                  <div
                    id="collapseOne5"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingOne5"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <div className="font-list-civil">
                        <ul>
                          <li>
                            CALL 9-1-1. Then call our 24/7 attorney hot line.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="accordion" id="accordionExample1">
                <div className="accordion-item">
                  <div className="accordion-header collapsed" id="headingOne7">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne7"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      How do I reset my password?
                    </button>
                  </div>
                  <div
                    id="collapseOne7"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingOne7"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <div className="font-list-civil">
                        <ul>
                          <li>
                            Click the Reset your password link below the
                            password section on your member log in portal.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="accordion" id="accordionExample1">
                <div className="accordion-item">
                  <div className="accordion-header" id="headingOne8">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne8"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      What do I get when I join?
                    </button>
                  </div>
                  <div
                    id="collapseOne8"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingOne8"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <div className="font-list-civil">
                        <ul>
                          <li>
                            Our association offers 3 pillars of extraordinary
                            value to help our members have the best protection
                            and education one can buy.
                          </li>
                          <li>
                            EDUCATION - expert information on legally exercising
                            your Second Amendment right.
                          </li>
                          <li>
                            ATTORNEY HOTLINE - immediate real-time assistance
                            from experienced legal claims experts
                          </li>
                          <li>
                            FINANCIAL SUPPORT & LEGAL PROTECTION - upfront
                            payment, no out-of-pocket costs, nothing to repay
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="accordion" id="accordionExample1">
                <div className="accordion-item">
                  <div className="accordion-header" id="headingOne9">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne9"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      I don’t have a concealed carry license. Is the association
                      still right for me?
                    </button>
                  </div>
                  <div
                    id="collapseOne9"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingOne9"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <div className="font-list-civil">
                        <ul>
                          <li>Everyone needs self defense protection.</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="accordion" id="accordionExample1">
                <div className="accordion-item">
                  <div className="accordion-header" id="headingOne-1">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne-1"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      Can I upgrade my level later?
                    </button>
                  </div>
                  <div
                    id="collapseOne-1"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingOne-1"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <div className="font-list-civil">
                        <ul>
                          <li>
                            Of course. Upgrading will always be available in the
                            member portal.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="accordion" id="accordionExample1">
                <div className="accordion-item">
                  <div className="accordion-header" id="headingOne-2">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne-2"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      What training is included with my membership?
                    </button>
                  </div>
                  <div
                    id="collapseOne-2"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingOne-2"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <div className="font-list-civil">
                        <ul>
                          <li>
                            A full curriculum of self defense videos and
                            articles are available.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="accordion" id="accordionExample1">
                <div className="accordion-item">
                  <div className="accordion-header" id="headingOne-3">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne-3"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      Can I pay my dues monthly?
                    </button>
                  </div>
                  <div
                    id="collapseOne-3"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingOne-3"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <div className="font-list-civil">
                        <ul>
                          <li>
                            Yes! You have the option to pay your dues either
                            monthly or annually.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="accordion" id="accordionExample1">
                <div className="accordion-item">
                  <div className="accordion-header" id="headingOne-4">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne-4"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      If I go to jail in self-defense, will you post my bail?
                    </button>
                  </div>
                  <div
                    id="collapseOne-4"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingOne-4"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <div className="font-list-civil">
                        <ul>
                          <li>
                            Yes, for an additional for $4 per month or $35 per
                            year, bail bond protection is available to our
                            associates up to $100,000.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="accordion" id="accordionExample1">
                <div className="accordion-item">
                  <div className="accordion-header" id="headingOne-5">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne-5"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      How long does it take for my ID card to show up?
                    </button>
                  </div>
                  <div
                    id="collapseOne-5"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingOne-5"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <div className="font-list-civil">
                        <ul>
                          <li>
                            Your associate ID card will be available online
                            immediately after joining.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="accordion" id="accordionExample1">
                <div className="accordion-item">
                  <div className="accordion-header" id="headingOne-8">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne-8"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      Definitions
                    </button>
                  </div>
                  <div
                    id="collapseOne-8"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingOne-8"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <div className="font-list-civil">
                        <ol>
                          <li>
                            <span>Civil Defense coverage</span>
                            allows you to choose your own attorney in the event
                            you are sued after a self-defense incident.
                          </li>
                          <li>
                            <span>Criminal Defense coverage</span>
                            provides legal support in the event you are
                            criminally charged after a self-defense incident.
                            (You choose your own attorney)
                          </li>
                          <li>
                            <span>Psychological support</span>
                            provides coverage for counseling sessions due to
                            stress/trauma experienced from a self-defense
                            incident.
                          </li>
                          <li>
                            <span>Expert Witness coverage</span> will provide an
                            “expert witness” to testify on your behalf.
                          </li>
                          <li>
                            <span>Multi state coverage</span>
                            Multi state coverage offers you protection in 50
                            states. Residents of New York, New Jersey, and
                            Washington do not qualify for our membership.
                          </li>
                          <li>
                            <span>Bail Bond Reimbursement</span>
                            provides money for bail up to $100,000.
                          </li>
                          <li>
                            <span>Additional Associate:</span>
                            extends coverage to a spouse or household partner at
                            the limit of coverage selected.
                          </li>
                        </ol>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="accordion" id="accordionExample1">
                <div className="accordion-item last-item-accordion">
                  <div className="accordion-header button-faq" id="heading">
                    <button
                      className="accordion-button collapsed last-accordion"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      Standard Association Benefits
                    </button>
                  </div>
                  <div
                    id="collapse"
                    className="accordion-collapse collapse"
                    aria-labelledby="heading"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <div className="font-list-civil">
                        <ol>
                          <li>24/7 365 Emergency Hotline</li>
                          <li>Coverage extends to all legal weapons.</li>
                          <li>
                            Member ID card is inside of your member portal.
                          </li>
                          <li>Multiple payment options.</li>
                          <li>
                            Home/Umbrella policies do not cover acts of
                            self-defense.
                          </li>
                        </ol>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
Accordion.propTypes = {
  heading: PropTypes.string,
};

export default Accordion;
