import React, { useEffect, useState } from 'react';
import AccountMainLayout from '../Layout/AccountMainLayout';
import { Link, useNavigate } from 'react-router-dom';
import { logoutUser } from '../utils/Storage';
import { getApi } from '../utils/api';
import { invoice } from '../utils/endpoint';
import Cookies from 'js-cookie';
import { generatePrice } from '../utils/helpers';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { TailSpin } from 'react-loader-spinner';

const Account = () => {
  const [userData, setUserData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { personalDetail } = useSelector((state) => state.signup);
  const navigate = useNavigate();

  const handleLogout = () => {
    logoutUser();
    window.location.replace('/');
  };

  useEffect(() => {
    let access_token = Cookies.get('userToken');
    setIsLoading(true);
    getApi(invoice, access_token)
      .then((res) => {
        if (res?.data?.success) {
          setUserData(res?.data?.data[0]);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  }, []);

  const handleInput = (id) => {
    navigate('/account-order', {
      state: {
        data: {
          id: id,
        },
      },
    });
  };
  return (
    <>
      <AccountMainLayout>
        <div className="account-login">
          <div className="container-fluid">
            <div className="account-user">
              <div className="row align-items-end">
                <div className="col-12">
                  <div className="account-log-out">
                    <h2 className="account-heading">Account</h2>
                    <Link onClick={handleLogout} className="link-account">
                      <i className="fa fa-user-o" aria-hidden="true"></i> Log
                      out
                    </Link>
                  </div>
                </div>

                <div className="col-md-8 col-lg-10">
                  {isLoading && (
                    <div className="loader">
                      <TailSpin
                        height="80"
                        width="80"
                        color="#aeaeae"
                        ariaLabel="tail-spin-loading"
                        radius="10"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                      />
                    </div>
                  )}
                  {!isLoading && (
                    <>
                      <div className="outer-account-heading">
                        <h2 className="account-heading">Order history</h2>
                        {!userData && (
                          <p className="account-sub-heading">
                            You haven&apos;t placed any orders yet.
                          </p>
                        )}
                      </div>
                      {userData && (
                        <div className="card card-table">
                          <table className="table-list">
                            <thead>
                              <tr>
                                <th>ORDER</th>
                                <th>DATE</th>
                                <th>PAYMENT STATUS</th>
                                <th>FULFILLMENT STATUS</th>
                                <th className="total-number">TOTAL</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <div onClick={() => handleInput(userData.id)}>
                                    <input
                                      type="text"
                                      style={{
                                        pointerEvents: 'none',
                                      }}
                                      value={userData?.number}
                                      placeholder="User Id"
                                    />
                                  </div>
                                </td>
                                <td>
                                  {moment
                                    .unix(userData.created)
                                    .format('MMMM DD, YYYY')}
                                </td>
                                <td>{userData?.paid && 'Paid'}</td>
                                <td>
                                  {userData?.paid ? 'Fulfilled' : 'Pending'}
                                </td>
                                <td className="table-date-list">
                                  {generatePrice(userData?.total / 100)}{' '}
                                  {userData && userData.currency
                                    ? userData.currency.toUpperCase()
                                    : 'USD'}
                                </td>
                              </tr>

                              {/* <tr>
                            <td>
                              <Link onClick={handleInput}>
                                <input
                                  type="text"
                                  disabled
                                  value={userData?.number}
                                  placeholder="User Id"
                                />
                              </Link>
                            </td>
                            <td>
                              {moment
                                .unix(userData.created)
                                .format("MMMM DD, YYYY")}
                            </td>
                            <td>{userData?.paid && "Paid"}</td>
                            <td>{userData?.paid ? "Fulfilled" : "Pending"}</td>
                            <td>
                              {generatePrice(userData?.total / 100)}{" "}
                              {userData && userData.currency
                                ? userData.currency.toUpperCase()
                                : "USD"}
                            </td>
                          </tr> */}
                            </tbody>
                          </table>
                        </div>
                      )}
                    </>
                  )}
                </div>
                <div className="col-md-4 col-lg-2">
                  <div className="account-details">
                    <h2 className="account-heading">Account details</h2>
                    {Object.keys(personalDetail).length > 0 && (
                      <p className="account-sub-heading">
                        {personalDetail?.name || personalDetail?.firstName}{' '}
                        {personalDetail?.last_name || personalDetail?.lastName}
                        <br />
                        {personalDetail?.address}
                        <br />
                        {personalDetail?.state + ' ' + personalDetail?.zipCode}
                        <br />
                        United States
                      </p>
                    )}

                    <Link to="/account-addresses" className="link-account">
                      View profile addresses (1)
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AccountMainLayout>
    </>
  );
};

export default Account;
