import AccountMainLayout from '../Layout/AccountMainLayout';
import { Link } from 'react-router-dom';
import React, { useRef, useState } from 'react';
import ErrorBanner from './ErrorBanner';
import Cookies from 'js-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { changePassword, updateProfile } from '../utils/endpoint';
import { postApi } from '../utils/api';
import * as Yup from 'yup';
import { state_city } from '../utils/state_cities';
import { setPersonalDetail } from '../redux/features/signup/signupSlice';
import { TailSpin } from 'react-loader-spinner';
import { emailRegExp, phoneRegExp } from '../utils/common';

const ProfilePreferences = () => {
  const { personalDetail } = useSelector((state) => state.signup);
  const [formData, setFormData] = useState({
    firstName:
      personalDetail?.firstName || personalDetail?.name
        ? personalDetail?.firstName || personalDetail?.name
        : '',
    lastName:
      personalDetail?.lastName || personalDetail?.last_name
        ? personalDetail?.lastName || personalDetail?.last_name
        : '',
    email: personalDetail.email ? personalDetail.email : '',
    phoneNumber:
      personalDetail?.phoneNumber || personalDetail?.telephone
        ? personalDetail?.phoneNumber || personalDetail?.telephone
        : '',
    password: personalDetail?.password ? personalDetail?.password : '00000000',
    address: personalDetail?.address ? personalDetail?.address : '',
    address2: personalDetail?.address2 ? personalDetail?.address2 : '',
    state: personalDetail?.state ? personalDetail?.state : '',
    city: personalDetail?.city ? personalDetail?.city : '',
    zipCode:
      personalDetail?.zipCode || personalDetail?.zipcode
        ? personalDetail?.zipCode || personalDetail?.zipcode
        : '',
    isAdditional: false,
    additionalFirstName: '',
    additionalLastName: '',
  });
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  let statelist = Object.keys(state_city);

  const [showError, setShowError] = useState(false);
  const [showErrorDetail, setShowErrorDetail] = useState({
    isError: true,
    message: '',
  });
  const formRef = useRef(null);
  const access_token = Cookies.get('userToken');

  const [errors, setErrors] = useState('');
  const handleChangePassword = (e) => {
    e.preventDefault();
    postApi(changePassword, {}, access_token)
      .then((res) => {
        if (res?.data?.success) {
          setShowErrorDetail({
            isError: false,
            message:
              'An email containing a password-reset link has been sent to you.',
          });
          setShowError(true);
          setTimeout(() => {
            setShowError(false);
            setShowErrorDetail({
              isError: true,
              message: '',
            });
          }, 7000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleClose = () => {
    setShowError(false);
    setShowErrorDetail({
      isError: true,
      message: '',
    });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    // Validation
    try {
      validationSchema.validateSyncAt(name, { [name]: value });
      setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
    } catch (error) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: error.message,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await validationSchema.validate(formData, {
        abortEarly: false,
      });
      setErrors('');
      const { city, state, ...personalDetail } = formData;
      const obj = {
        city,
        state,
        ...personalDetail,
      };
      console.log(obj);
      if (!state_city[state].includes(city)) {
        setShowError(true);
        setShowErrorDetail({
          isError: false,
          message: 'Not a valid United States city.',
        });
        setTimeout(() => {
          setShowError(false);
          setShowErrorDetail({
            isError: true,
            message: '',
          });
        }, 3000);
        setIsLoading(false);
      } else {
        const formdata = new FormData();
        formdata.append('name', formData.firstName);
        formdata.append('last_name', formData.lastName);
        formdata.append('email', formData.email);
        formdata.append('telephone', formData.phoneNumber);
        formdata.append('city', formData.city);
        formdata.append('state', formData.state);
        formdata.append('zipcode', formData.zipCode);
        formdata.append('address', formData.address);
        formdata.append('address2', formData.address2);
        postApi(updateProfile, formdata, access_token)
          .then((res) => {
            if (res?.data?.success) {
              window.scrollTo(0, 0);
              setShowErrorDetail({
                isError: false,
                message: 'Updated!',
              });
              setShowError(true);
              setTimeout(() => {
                setShowError(false);
                setShowErrorDetail({
                  isError: true,
                  message: '',
                });
              }, 7000);

              localStorage.setItem('userData', JSON.stringify(res?.data?.data));
              dispatch(setPersonalDetail(res?.data?.data));
              setIsLoading(false);
            }
          })
          .catch((err) => {
            console.log(err);
            setIsLoading(false);
          });
      }
    } catch (error) {
      const validationErrors = {};
      error.inner.forEach((err) => {
        validationErrors[err.path] = err.message;
      });

      if (Object.entries(validationErrors).length === 0) {
        setShowError(true);
        setShowErrorDetail({
          isError: true,
          message: 'Please fill all the required fields.',
        });
        setTimeout(() => {
          setShowError(false);
          setShowErrorDetail({
            isError: true,
            message: '',
          });
        }, 3000);
      }
      setErrors(validationErrors);
      setIsLoading(false);
    }
  };

  const handleManualSubmit = async (e) => {
    e.preventDefault();
    const form = formRef.current;
    if (form.checkValidity()) {
      setIsLoading(true);
      await handleSubmit(e);
    } else {
      setShowError(true);
      setShowErrorDetail({
        isError: true,
        message: 'Please fill all the required fields.',
      });
      setTimeout(() => {
        setShowError(false);
        setShowErrorDetail({
          isError: true,
          message: '',
        });
      }, 3500);
      form.reportValidity();
    }
  };
  const styleClass = {
    color: 'red',
  };

  return (
    <>
      <AccountMainLayout>
        {showError && (
          <ErrorBanner error={showErrorDetail} handleClose={handleClose} />
        )}
        <form className="user-details account-login" id="earlyAccessForm">
          <div className="container-fluid">
            <div className="row">
              <div className="link-profile">
                <ul>
                  <li>
                    <Link>My Profile</Link>
                  </li>
                </ul>
              </div>
              <div className="col-lg-7">
                <div className="row">
                  <div className="col-lg-6"></div>
                  <div className="col-lg-6">
                    <button
                      type="button"
                      onClick={handleChangePassword}
                      className="theme-button login-button w-100 mt-4"
                    >
                      Change password
                    </button>
                  </div>
                  <form ref={formRef}>
                    <div className="row">
                      <div className="col-md-12">
                        <h2 className="primary-heading">PRIMARY ASSOCIATE</h2>
                      </div>
                      <div className="col-lg-6">
                        <label
                          htmlFor="firstName"
                          className="form-label label-text"
                        >
                          First name*
                        </label>
                        <input
                          type="text"
                          className="form-control input-outline"
                          placeholder="First name*"
                          value={formData.firstName}
                          onChange={handleInputChange}
                          name="firstName"
                          required
                        />
                        {errors.firstName && (
                          <div style={styleClass}>{errors.firstName}</div>
                        )}
                      </div>
                      <div className="col-lg-6">
                        <label
                          htmlFor="lastname"
                          className="form-label label-text"
                        >
                          Last name*
                        </label>
                        <input
                          type="text"
                          className="form-control input-outline"
                          placeholder="Last name*"
                          value={formData.lastName}
                          name="lastName"
                          onChange={handleInputChange}
                          required
                        />
                        {errors.lastName && (
                          <div style={styleClass}>{errors.lastName}</div>
                        )}
                      </div>
                      <div className="col-md-6">
                        <label
                          htmlFor="email"
                          className="form-label label-text"
                        >
                          Email Address
                        </label>
                        <input
                          type="email"
                          className="form-control input-outline"
                          placeholder="Email Address"
                          value={formData.email}
                          onChange={handleInputChange}
                          name="email"
                          readOnly
                          required
                        />
                        {errors.email && (
                          <div style={styleClass}>{errors.email}</div>
                        )}
                      </div>
                      {/* <div className="col-md-6">
                        <label
                          htmlFor="password"
                          className="form-label label-text"
                        >
                          Password
                        </label>
                        <input
                          type="password"
                          className="form-control input-outline"
                          placeholder="Password"
                          value={formData.password}
                          name="password"
                          disabled
                        />
                      </div> */}
                      <div className="col-md-6">
                        <label
                          htmlFor="phoneNumber"
                          className="form-label label-text"
                        >
                          Phone Number*
                        </label>
                        <input
                          type="tel"
                          maxLength="10"
                          className="form-control input-outline"
                          placeholder="Phone Number"
                          value={formData.phoneNumber}
                          onChange={handleInputChange}
                          name="phoneNumber"
                          onKeyPress={(e) => {
                            const regex = /^[0-9\b]+$/;
                            if (!regex.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                          required
                        />
                        {errors.phoneNumber && (
                          <div style={styleClass}>{errors.phoneNumber}</div>
                        )}
                      </div>
                      {formData?.isAdditional && (
                        <>
                          <div className="col-md-12">
                            <h2 className="primary-heading">
                              ADDITIONAL ASSOCIATE
                            </h2>
                          </div>
                          <div className="col-md-6">
                            <label
                              htmlFor="additionalFirstName"
                              className="form-label label-text"
                            >
                              First name*
                            </label>
                            <input
                              type="text"
                              className="form-control input-outline"
                              placeholder="First name*"
                              value={formData.additionalFirstName}
                              name="additionalFirstName"
                              required
                              onChange={handleInputChange}
                            />
                            {errors.additionalFirstName && (
                              <div style={styleClass}>
                                {errors.additionalFirstName}
                              </div>
                            )}
                          </div>
                          <div className="col-md-6">
                            <label
                              htmlFor="additionalLastName"
                              className="form-label label-text"
                            >
                              Last name*
                            </label>
                            <input
                              type="text"
                              className="form-control input-outline"
                              placeholder="Last name*"
                              value={formData.additionalLastName}
                              name="additionalLastName"
                              required
                              onChange={handleInputChange}
                            />
                            {errors.additionalLastName && (
                              <div style={styleClass}>
                                {errors.additionalLastName}
                              </div>
                            )}
                          </div>
                        </>
                      )}
                      <div className="col-md-12">
                        <h2 className="primary-heading">BILLING ADDRESS</h2>
                      </div>
                      <div className="col-md-12">
                        <label
                          htmlFor="address"
                          className="form-label label-text"
                        >
                          Street Address 1*
                        </label>
                        <input
                          type="address"
                          className="form-control input-outline"
                          placeholder="Street Address 1*"
                          value={formData.address}
                          onChange={handleInputChange}
                          name="address"
                          required
                        />
                        {errors.address && (
                          <div style={styleClass}>{errors.address}</div>
                        )}
                      </div>
                      <div className="col-md-12">
                        <label
                          htmlFor="address2"
                          className="form-label label-text"
                        >
                          Street Address 2*
                        </label>
                        <input
                          type="address"
                          className="form-control input-outline"
                          placeholder="Street Address 2*"
                          value={formData.address2}
                          onChange={handleInputChange}
                          name="address2"
                        />
                      </div>
                      <div className="col-md-6">
                        <label
                          htmlFor="input_State"
                          className="form-label label-text"
                        >
                          State
                        </label>
                        <select
                          id="input_State"
                          className="form-select input-outline"
                          name="state"
                          value={formData.state}
                          onChange={handleInputChange}
                          required
                        >
                          <option disabled>Select State</option>
                          {statelist.map((item, index) => (
                            <option value={item} key={index}>
                              {item}
                            </option>
                          ))}
                        </select>
                        <p className="form-membership">
                          We do not offer memberships for those in the following
                          states: New York, New Jersey, Washington
                        </p>
                      </div>
                      <div className="col-md-6">
                        <label
                          htmlFor="inputCity"
                          className="form-label label-text"
                        >
                          City
                        </label>
                        <input
                          type="text"
                          className="form-control input-outline"
                          id="inputCity"
                          placeholder="City"
                          name="city"
                          onChange={handleInputChange}
                          value={formData.city}
                          required
                        />
                      </div>
                      <div className="col-md-12">
                        <label
                          htmlFor="inputZip"
                          className="form-label label-text"
                        >
                          Postal/ZIP code
                        </label>
                        <input
                          type="text"
                          className="form-control input-outline"
                          id="inputZip"
                          placeholder="Postal/ZIP code"
                          name="zipCode"
                          value={formData.zipCode}
                          onChange={handleInputChange}
                          maxLength="5"
                          onKeyPress={(e) => {
                            const regex = /^[0-9\b]+$/;
                            if (!regex.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                          required
                        />
                      </div>

                      <div className="text-end mb-5">
                        <button
                          className={`theme-button login-button ${
                            isLoading ? 'disable' : ''
                          }`}
                          form="earlyAccessForm"
                          onClick={handleManualSubmit}
                        >
                          {isLoading ? (
                            <div className="d-flex justify-content-center">
                              <TailSpin
                                height="30"
                                width="30"
                                color="#fff"
                                ariaLabel="tail-spin-loading"
                                radius="1"
                                wrapperStyle={{}}
                                wrapperClass=""
                                visible={true}
                              />
                            </div>
                          ) : (
                            'Save'
                          )}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-lg-5"></div>
            </div>
          </div>
        </form>
      </AccountMainLayout>
    </>
  );
};

export default ProfilePreferences;

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  email: Yup.string()
    .email('Invalid email address')
    .required('Email is required')
    .matches(emailRegExp, 'Please enter a valid Email Address'),
  phoneNumber: Yup.string()
    .required('Phone number is required')
    .matches(phoneRegExp, 'Please enter valid mobile number'),
  address: Yup.string().required('Address is required'),
  state: Yup.string().required('State is required'),
});
