import React, { useEffect } from 'react';
import { useState } from 'react';
import { handleTabAction } from '../../utils/helpers';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const QuestionnaireRange = ({ handleQuestionnaire, handleTabChange }) => {
  const [question1, setQuestion1] = useState('No');
  const [question2, setQuestion2] = useState('No');
  const [isNextDisabled, setIsNextDisabled] = useState(false);
  const [showError, setShowError] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === 'myradio-q1') {
      setQuestion1(value);
    } else if (name === 'myradio-q2') {
      setQuestion2(value);
    }
  };

  const handleNext = () => {
    handleTabAction('questionnaire', 'review');
    handleQuestionnaire('review');
  };

  const handleBack = () => {
    handleTabChange('billing');
    handleTabAction('questionnaire', 'billing');
  };

  useEffect(() => {
    var element = document.getElementsByClassName('next-btn')[0];
    if (question1 === 'Yes' || question2 === 'Yes') {
      setIsNextDisabled(true);
      element.classList.add('disable');
      setShowError(true);
    } else {
      setIsNextDisabled(false);
      setShowError(false);
      element.classList.remove('disable');
    }
  }, [question1, question2]);

  return (
    <>
      {console.log(isNextDisabled)}
      {showError && (
        <div className="alert_inner error-background">
          <span>Sorry...You are not eligible.</span>
          <a
            className="close__alert"
            onClick={() => {
              setShowError(false);
            }}
          >
            ×
          </a>
        </div>
      )}
      <div className="join-form-user option-user d-none">
        <div className="container-fluid">
          <div className="row">
            <div className="outer-event-policy mt-4">
              <h4>
                Have you had any incident, event, occurrence, loss, or wrongful
                act prior to the inception of this policy, which might give rise
                to a claim?
              </h4>
              <div className="radio-button">
                <input
                  className="radio-input"
                  type="radio"
                  name="myradio-q1"
                  id="myradio33"
                  value="Yes"
                  checked={question1 === 'Yes'}
                  onChange={handleInputChange}
                />
                <label htmlFor="myradio33" className="radio__label">
                  <p>Yes</p>
                </label>
                <input
                  className="radio-input"
                  type="radio"
                  name="myradio-q1"
                  id="myradio34"
                  value="No"
                  checked={question1 === 'No'}
                  onChange={handleInputChange}
                />
                <label htmlFor="myradio34" className="radio__label">
                  <p>No</p>
                </label>
              </div>
            </div>
            <div className="outer-event-policy">
              <h4>Have you ever been adjudicated as mentally incompetent?</h4>
              <div className="radio-button">
                <input
                  className="radio-input"
                  type="radio"
                  name="myradio-q2"
                  id="myradio-3"
                  value="Yes"
                  checked={question2 === 'Yes'}
                  onChange={handleInputChange}
                />
                <label htmlFor="myradio-3" className="radio__label">
                  <p>Yes</p>
                </label>
                <input
                  className="radio-input"
                  type="radio"
                  name="myradio-q2"
                  id="myradioTwo"
                  value="No"
                  checked={question2 === 'No'}
                  onChange={handleInputChange}
                />
                <label htmlFor="myradioTwo" className="radio__label">
                  <p>No</p>
                </label>
              </div>
            </div>

            <div className="button-back">
              <a onClick={handleBack} className="back-text">
                Back
              </a>
              <button
                type="submit"
                className="theme-button next-btn disable mobile-button-view"
                onClick={handleNext}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="questionnaire-range d-none">
        <div className="container-fluid">
          <div className="row">
            <div className="billing-range ps-4 py-4">
              <h4 className="sub-title-2 ">
                Range Membership Application - The Range Network
              </h4>
              <p className="bear-text">
                Please verify the following is correct. Click on &ldquo;Complete
                Your Registrations&ldquo; to Continue.
              </p>
              <label htmlFor="name" className="form-label label-text">
                Member(s) To Be Registered:
              </label>
              <ul>
                <li>Holder,Jason</li>
                <li>Universal City</li>
                <li>Miami,AK 45678</li>
                <li>rangenetwork@yopmail.com</li>
                <li>$0.00:Participating</li>
              </ul>
            </div>
            <div className="col-md-12">
              <div className="last mb-5 px-3">
                <Link to="#" className="back">
                  Back
                </Link>
                <Link to="#" className="theme-button">
                  Continue
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <form className="details-user-form py-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-8">
              <div className="row">
                <div className="col-md-12">
                  <label
                    htmlFor="inputState1"
                    className="form-label label-text"
                  >
                    Referred By
                  </label>
                  <select
                    id="inputState1"
                    className="form-select input-outline"
                    name="billingState"
                    // onChange={handleInputChange}
                    // value={formData.billingState}
                  >
                    <option selected>Brochure</option>
                    {/* {statelist.map((item, index) => (
                      <option value={item} key={index}>
                        {item}
                      </option>
                    ))} */}
                  </select>
                </div>
                <div className="col-md-12">
                  <label
                    htmlFor="inputState1"
                    className="form-label label-text"
                  >
                    Other
                  </label>
                  <input type="text" className="input-outline" />
                </div>
                <div className="col-md-12">
                  <div className="form-check mb-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="Range1"
                    />
                    <label className="form-check-label" htmlFor="Range1">
                      I would like to know more about becoming a Partner Range
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="Range2"
                    />
                    <label className="form-check-label" htmlFor="Range2">
                      Veteran-Owned Business
                    </label>
                  </div>
                </div>
                <div className="col-md-12">
                  <label
                    htmlFor="MembershipInformation"
                    className="form-label label-text mt-3"
                  >
                    Membership Information *
                  </label>
                  <select
                    id="inputState1"
                    className="form-select input-outline"
                    name="billingState"
                    required
                  >
                    <option selected>1-100 Members</option>

                    <option value="101-350 Member">101-350 Member</option>
                    <option value="351-600 Member">350-600 Member</option>
                    <option value="600-999 Member">600-999 Member</option>
                    <option value="1000+ Member">1000+ Member</option>
                  </select>
                </div>
                <div className="col-md-12 mb-3">
                  <label
                    htmlFor="TypesOfMemberships*"
                    className="form-label label-text mb-0"
                  >
                    Types of Memberships*
                  </label>
                  <p>(select ALL that apply)</p>
                  <div className="select-mm">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="Monthly"
                        required
                      />
                      <label className="form-check-label" htmlFor="Monthly">
                        Monthly
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="Annual"
                      />
                      <label className="form-check-label" htmlFor="Annual">
                        Annual
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="None"
                      />
                      <label className="form-check-label" htmlFor="None">
                        None
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="Other"
                      />
                      <label className="form-check-label" htmlFor="Other">
                        Other
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <label
                    htmlFor="Range Features"
                    className="form-label label-text mb-0"
                  >
                    Range Features
                  </label>
                  <p>Please select all that apply.</p>
                  <div className="select-mm">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        required
                        id="Indoor"
                      />
                      <label className="form-check-label" htmlFor="Indoor">
                        Indoor
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="Outdoor"
                      />
                      <label className="form-check-label" htmlFor="Outdoor">
                        Outdoor
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="Archery"
                      />
                      <label className="form-check-label" htmlFor="Archery">
                        Archery
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="Training"
                      />
                      <label className="form-check-label" htmlFor="Training">
                        Training Facility
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="Public"
                      />
                      <label className="form-check-label" htmlFor="Public">
                        Public
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="Private"
                      />
                      <label className="form-check-label" htmlFor="Private">
                        Private
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <label
                    htmlFor="inputState1"
                    className="form-label label-text mt-3 mb-0"
                  >
                    Other
                  </label>
                  <p>(Please Explain)</p>
                  <input type="text" className="input-outline" />
                </div>
                <div className="col-md-6">
                  <label htmlFor="number" className="form-label label-text">
                    Lane Count*
                  </label>
                  {/* <p>(Please Explain)</p> */}
                  <input
                    type="number"
                    required
                    className="form-control input-outline"
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="number" className="form-label label-text">
                    Number of Employees*
                  </label>

                  <input
                    type="number"
                    className="form-control input-outline"
                    required
                  />
                </div>
                <div className="col-md-12">
                  <label
                    htmlFor=" Gun Rentals"
                    className="form-label label-text mb-0"
                  >
                    Gun Rentals*
                  </label>
                  <p>(select ALL that apply)</p>
                  <div className="select-mm">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="Handgun"
                        required
                      />
                      <label className="form-check-label" htmlFor="Handgun">
                        Handgun
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="Long Gun"
                      />
                      <label className="form-check-label" htmlFor="Long Gun">
                        Long Gun
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="Suppressed"
                      />
                      <label className="form-check-label" htmlFor="Suppressed">
                        Suppressed
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="Full Auto"
                      />
                      <label className="form-check-label" htmlFor="Full Auto">
                        Full Auto
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <label
                    htmlFor="number"
                    className="form-label label-text mb-0 mt-3"
                  >
                    Range Social Media Handles
                  </label>
                  <p>
                    (EX: Twitter - @therangenetwork, Instagram -
                    @therangenetwork)
                  </p>
                  <textarea
                    className="form-control input-outline"
                    id="floatingTextarea"
                  ></textarea>
                </div>
                <div className="col-md-6">
                  <label htmlFor="time" className="form-label label-text">
                    Hours of Operation*
                  </label>
                  <input
                    type="time"
                    className="form-control input-outline"
                    required
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="name" className="form-label label-text">
                    # of Range Locations*
                  </label>
                  <input
                    type="number"
                    className="form-control input-outline"
                    required
                  />
                </div>
                <div className="col-md-12">
                  <p>
                    About (We would like to hear more about you and your range,
                    feel free to comment below anything that would help us
                    understand you and/or your range more.):
                  </p>
                  <textarea
                    className="form-control input-outline"
                    id="floatingTextarea"
                  ></textarea>
                </div>
                <div className="col-md-12">
                  <p>List any unique features specific to your range.:</p>
                  <textarea
                    className="form-control input-outline"
                    id="floatingTextarea"
                  ></textarea>
                </div>
                <div className="col-md-12">
                  <div className="last">
                    <Link to="/membership-application" className="back">
                      Back
                    </Link>
                    <Link
                      to="#"
                      className="theme-button"
                      // onClick={handleManualSubmit}
                    >
                      Continue
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};
QuestionnaireRange.propTypes = {
  handleQuestionnaire: PropTypes.func,
  handleTabChange: PropTypes.func,
};

export default QuestionnaireRange;
