import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const Header = () => {
  const [activeItem, setActiveItem] = useState('');
  const [show, setShow] = useState(false);

  useEffect(() => {
    const path = window.location.pathname;
    setActiveItem(path);
  }, []);

  return (
    <>
      <div className="navbar d-lg-block d-none">
        <div className="container">
          <header>
            <a href="/" className="logo">
              <img src="../../images/logo.svg" alt="" />
            </a>
            <nav>
              <ul className="menu-bar">
                <li className={activeItem === '/how-to-works' ? 'active' : ''}>
                  <Link to="/how-to-works">How it Works</Link>
                </li>
                <li className={activeItem === '/why-to-join' ? 'active' : ''}>
                  <Link to="/why-to-join">Why Join</Link>
                </li>

                <li className={activeItem === '/find-a-range' ? 'active' : ''}>
                  <Link to="/find-a-range">Find a Range</Link>
                </li>
                <li className={activeItem === '/join-now' ? 'active' : ''}>
                  <Link to="/join-now">Join Now</Link>
                </li>
              </ul>
              <div className="dropdown btn-group join-button d-none">
                <Link
                  className={
                    activeItem === '/join-now' ||
                    activeItem === '/participating'
                      ? 'link-join active-drop'
                      : 'link-join'
                  }
                  to="/profile-preferences"
                  role="button"
                  id="dropdownMenuLink"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Join Now
                  <i className="fa fa-caret-down" aria-hidden="true"></i>
                </Link>

                <ul
                  className="dropdown-menu "
                  aria-labelledby="dropdownMenuLink"
                >
                  <li>
                    <Link className="dropdown-item border-list" to="/join-now">
                      Become a Member
                    </Link>
                  </li>
                  <li>
                    <Link to="/participating" className="dropdown-item">
                      Become a Participating Range
                    </Link>
                  </li>
                </ul>
              </div>
            </nav>
            <div className="log-in">
              <Link
                to="/login"
                className={activeItem === '/login' ? 'active' : ''}
              >
                LOG IN
              </Link>
            </div>
          </header>
        </div>
      </div>
      <div className="navbar navbar-mobile-menu d-lg-none">
        <div className="container-fluid">
          <header>
            <Link to="/" className="logo">
              <img src="../../images/logo.svg" alt="" />
            </Link>
            <nav>
              <input type="checkbox" id="click" />
              <label htmlFor="click" className="menu-btn">
                <i className="fa fa-align-right"></i>
              </label>
              <ul className="menu-bar">
                <li className={activeItem === '/login' ? 'active' : ''}>
                  <Link to="/login">LOG IN</Link>
                </li>
                <li className={activeItem === '/how-to-works' ? 'active' : ''}>
                  <Link to="/how-to-works">How it Works</Link>
                </li>
                <li className={activeItem === '/why-to-join' ? 'active' : ''}>
                  <Link to="/why-to-join">Why Join</Link>
                </li>

                <li className={activeItem === '/find-a-range' ? 'active' : ''}>
                  <Link to="/find-a-range">Find a Range</Link>
                </li>
                <li
                  className={
                    activeItem === '/join-now'
                      ? 'active join-set-2'
                      : 'join-set-2'
                  }
                >
                  <Link
                    to="/join-now"
                    className="dropdown-img"
                    onClick={() => setShow(!show)}
                  >
                    Join Now
                  </Link>

                  {show ? (
                    <ul className="d-none">
                      <li>
                        <Link to="/join-now">Become a Member</Link>
                      </li>
                      <li>
                        <Link to="/participating">
                          Become a Participating Range
                        </Link>
                      </li>
                    </ul>
                  ) : null}
                </li>
                <li
                  className={
                    activeItem === 'https://therangenetwork.com/'
                      ? 'active'
                      : ''
                  }
                >
                  <Link to="https://therangenetwork.com/">
                    About The Range NewWork
                  </Link>
                </li>
                <li className={activeItem === '#' ? 'active' : ''}>
                  <Link to="#">Customer Service</Link>
                </li>
                <li className={activeItem === '#' ? 'active' : ''}>
                  <Link to="#">Card Member Agreement</Link>
                </li>
                <li
                  className={
                    activeItem ===
                    'https://www.therangenetwork.com/become_a_participating_range.php'
                      ? 'active'
                      : ''
                  }
                >
                  <Link to="https://www.therangenetwork.com/become_a_participating_range.php">
                    Become a Partner Range
                  </Link>
                </li>
                <li
                  className={
                    activeItem ===
                    'https://therangenetwork.com/privacy_notice.php'
                      ? 'active'
                      : ''
                  }
                >
                  <Link to="https://therangenetwork.com/privacy_notice.php">
                    Privacy
                  </Link>
                </li>
                <li className={activeItem === '#' ? 'active' : ''}>
                  <Link to="#">Terms of Service</Link>
                </li>
                {/* <li className="d-flex">
                  <Link to="#">
                    <i className="fa fa-twitter"></i>
                  </Link>
                  <Link to="#">
                    <i className="fa fa-facebook-official"></i>
                  </Link>
                  <Link to="#">
                    <i className="fa fa-youtube-play"></i>
                  </Link>
                </li> */}
              </ul>
            </nav>
          </header>
        </div>
      </div>
    </>
  );
};

export default Header;
