import React from 'react';
import MainLayout from '../Layout/MainLayout';
import { Link } from 'react-router-dom';

const MembershipApplication = () => {
  return (
    <>
      <MainLayout>
        <div className="congratulations">
          <div className="container">
            <div className="con-membership">
              <h4 className="sub-title-2">
                Range Membership Application - The Range Network
              </h4>
              <p className="bear-text pt-4">
                Below are the details for the Membership Plan selected. To
                continue with registration, click on the Continue button.
              </p>
              <ul>
                <li>
                  Membership Plan: <b>Participating</b>
                </li>
                <li>
                  Monthly Dues: <b>$ 0.00</b>
                </li>
                <li>
                  Discount (No Discount Applied): <b>-$ 0.00</b>
                </li>
                <li>
                  TOTAL: <b>$ 0.00</b>
                </li>
              </ul>
              <Link to="/plan-checkout" className="theme-button">
                Continue
              </Link>
            </div>
          </div>
        </div>
      </MainLayout>
    </>
  );
};

export default MembershipApplication;
