import React, { useEffect, useRef, useState } from 'react';
import Bill from '../../components/Bill';
import { getApi } from '../../utils/api';
import { generatePrice, handleTabAction } from '../../utils/helpers';
import { packages } from '../../utils/endpoint';
import { useDispatch, useSelector } from 'react-redux';
import { setBillingDetail } from '../../redux/features/signup/signupSlice';
import PropTypes from 'prop-types';

const Billing = ({ handleBilling, handleTabChange }) => {
  const [plan, setPlan] = useState([]);
  const [planList, setPlanList] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState({});
  const [total, setTotal] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [selectedStripeIds, setSelectedStripeIds] = useState([]);

  const formRef = useRef(null);
  let packagePlan = localStorage.getItem('selectedPlan');
  let selectedPlan = packagePlan ? JSON.parse(packagePlan) : plan;
  const [planYearly, setPlanYearly] = useState(
    selectedPlan?.name ? selectedPlan?.name : ''
  );

  const { billingDetail } = useSelector((state) => state.signup);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      getApi(packages)
        .then((res) => {
          setPlan(res.data?.data[0]);
          setPlanList(res.data?.data);

          if (selectedPlan) {
            let data = {};
            let planPackage = selectedPlan;
            data.planName = planPackage.name;
            data.id = planPackage.id;
            let stripeArray = [];
            planPackage?.packages[0]?.package_prices.map((item) => {
              data.planPrice = Number(item.price);
              data.priceFrequency = item.price_frequency;
              data.stripeId = item.stripe_price_id;
              stripeArray.push(item.stripe_price_id);
            });
            setSelectedPackage({
              billingOption: data,
            });
            setSelectedStripeIds(stripeArray);
          }
          if (planYearly === '') {
            let data = {};
            let planPackage = res.data?.data[0];
            data.planName = planPackage.name;
            data.id = planPackage.id;
            let stripeArray = [];
            planPackage?.packages[0]?.package_prices.map((item) => {
              data.planPrice = Number(item.price);
              data.priceFrequency = item.price_frequency;
              data.stripeId = item.stripe_price_id;
              stripeArray.push(item.stripe_price_id);
            });
            setSelectedPackage({
              billingOption: data,
            });
            setPlanYearly(planPackage.name);
            setSelectedStripeIds(stripeArray);
          }

          if (Object.keys(billingDetail).length > 0) {
            // let packages = res.data?.data[0]?.packages;
            setPlanYearly(billingDetail?.selectedPackage?.billingOption?.name);
            setSelectedPackage(billingDetail.selectedPackage);
          }
        })
        .catch((er) => {
          console.log(er);
        });
    };
    fetchData();
  }, []);

  useEffect(() => {
    let planAmount = selectedPackage?.billingOption
      ? selectedPackage?.billingOption?.planPrice
      : 0;
    let sum = Number(planAmount);
    setSubTotal(sum);
    // let tax = (sum * 5) / 100;
    // let total = tax + sum;
    // setTax(tax);
    setTotal(sum);
  }, [selectedPackage]);

  const handleChangePlan = (e, plan_data) => {
    console.log(e.target.value);
    setPlanYearly(plan_data.name);
    // setCheckedItems({});
    // setSelectedStripeIds([]);
    // setSelectedPackage({});
    let data = {};
    let stripeArray = [];
    data.planName = plan_data.name;
    data.id = plan_data.id;
    // plan_data?.packages[0]?.package_prices.map((item) => {
    data.planPrice = plan_data.price;
    data.priceFrequency = plan_data.priceFrequency;
    data.stripeId = plan_data.stripeId;
    stripeArray.push(plan_data.stripeId);
    // });
    setSelectedStripeIds(stripeArray);
    setSelectedPackage({
      billingOption: data,
    });
  };

  const handleBack = () => {
    handleTabChange('profile');
    handleTabAction('billing', 'profile');
  };

  const handleNext = (e) => {
    e.preventDefault();
    handleTabAction('billing', 'questionnaire');
    let data = {
      selectedStripeIds,
      selectedPackage,
      total,
      subTotal,
    };
    dispatch(setBillingDetail(data));
    handleBilling('questionnaire');
  };

  const handleManualSubmit = (e) => {
    e.preventDefault();
    const form = formRef.current;
    if (form.checkValidity()) {
      handleNext(e);
    } else {
      form.reportValidity();
    }
  };

  const flatPlanList = planList.flatMap((item) =>
    item?.packages?.flatMap((planItem) =>
      planItem?.package_prices?.map((price) => ({
        id: item.id,
        planId: planItem.id,
        price: price.price,
        name: item.name,
        priceFrequency: price.price_frequency,
        stripeId: price.stripe_price_id,
      }))
    )
  );

  return (
    <>
      <div className="join-form-user">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-8">
              <form ref={formRef}>
                <div className="row gx-3 pt-2">
                  <h4 className="billing-plan">PLAN SELECTION</h4>
                  {flatPlanList &&
                    flatPlanList?.map((item, index) => (
                      <div className="col-md-4" key={index}>
                        <div className="radioBtn">
                          <input
                            className="radio-input"
                            type="radio"
                            name="planradio"
                            id={`planradio${index}`}
                            value={item.name}
                            checked={planYearly === item.name}
                            onChange={(e) => handleChangePlan(e, item)}
                          />
                          <label
                            htmlFor={`planradio${index}`}
                            className="radio__label"
                          >
                            <p>${item.price}</p>
                            <p>{item.name}</p>
                          </label>
                        </div>
                      </div>
                    ))}
                  <hr className="mt-4 mb-4" />
                  <div className="button-back">
                    <a onClick={handleBack} className="back-text">
                      Back
                    </a>
                    <button
                      type="button"
                      onClick={handleManualSubmit}
                      className="theme-button mobile-button-view"
                    >
                      Next
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div className="col-md-4">
              <Bill
                Heading="PLAN SUMMARY"
                Yearly={
                  selectedPackage
                    ? selectedPackage?.billingOption?.planName
                    : 'Associate Coverage Yearly'
                }
                Dollar={
                  selectedPackage
                    ? selectedPackage?.billingOption?.planPrice &&
                      '$' + selectedPackage?.billingOption?.planPrice
                    : '$125.00'
                }
                PlanUser="Plan"
                Price={
                  selectedPackage
                    ? selectedPackage?.billingOption?.planPrice &&
                      '$' + selectedPackage?.billingOption?.planPrice
                    : '$125.00'
                }
                Add="Add Ons"
                AddPrice={
                  selectedPackage
                    ? selectedPackage?.addonPrice === undefined ||
                      selectedPackage?.addonPrice === null ||
                      isNaN(selectedPackage?.addonPrice) ||
                      selectedPackage?.addonPrice === 0
                      ? '-'
                      : generatePrice(selectedPackage?.addonPrice)
                    : '-'
                }
                Additional="Additional Associate One Time Fee"
                AdditionalPrice={
                  selectedPackage
                    ? selectedPackage?.additionalAddon === undefined ||
                      selectedPackage?.additionalAddon === 0
                      ? '-'
                      : generatePrice(selectedPackage?.additionalAddon)
                    : '-'
                }
                Tax="Tax (0.00)"
                TaxPrice={generatePrice(0)}
                Total="Total"
                TotalPrice={generatePrice(total)}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

Billing.propTypes = {
  handleBilling: PropTypes.func,
  handleTabChange: PropTypes.func,
};
export default Billing;
