import React, { useEffect, useState } from 'react';
import MainLayout from '../../Layout/MainLayout';
import AddressRange from './AddressRange';
import QuestionnaireRange from './QuestionnaireRange';
// import Referral from '../Become-Member/Referral';
import Referral from './Referral';
import { Link } from 'react-router-dom';
import ErrorBanner from '../ErrorBanner';
// import { handleTabAction } from '../../utils/helpers';
import InformationRange from './InformationRange';

const RangeMembershipApplication = () => {
  const [showError, setShowError] = useState(false);
  const [showErrorDetail, setShowErrorDetail] = useState({
    isError: true,
    message: '',
  });
  const [activeTab, setActiveTab] = useState('profile');
  const [currentTab, setCurrentTab] = useState('profile');
  const [profileTabCompleted, setProfileTabCompleted] = useState(false);
  const [billingTabCompleted, setBillingTabCompleted] = useState(false);
  const [questionnaireTabCompleted, setQuestionnaireTabCompleted] =
    useState(false);

  const handleTabChange = (tabId) => {
    console.log(
      currentTab,
      profileTabCompleted,
      billingTabCompleted,
      questionnaireTabCompleted
    );
    // const validation = validateTabChange(tabId);
    // if (validation.isValid) {
    //   if (
    //     currentTab === 'billing' &&
    //     profileTabCompleted &&
    //     tabId !== 'profile'
    //   ) {
    //     setActiveTab(currentTab);
    //     handleTabCss(currentTab);
    //   } else if (
    //     currentTab === 'questionnaire' &&
    //     billingTabCompleted &&
    //     tabId !== 'profile' &&
    //     tabId !== 'billing'
    //   ) {
    //     setActiveTab(currentTab);
    //     handleTabCss(currentTab);
    //   } else {
    //     setActiveTab(tabId);
    //     handleTabCss(tabId);
    //   }
    // } else {
    //   if (!validation.isCurrentTabCompleted) {
    //     setShowErrorDetail({
    //       isError: false,
    //       message: validation.error,
    //     });
    //     setShowError(true);
    //     setTimeout(() => {
    //       setShowError(false);
    //       setShowErrorDetail({
    //         isError: true,
    //         message: '',
    //       });
    //     }, 3000);
    //   }
    //   handleTabCss(activeTab);
    //   handleTabAction(tabId, activeTab);
    // }
    setActiveTab(tabId);
    handleTabCss(tabId);
  };

  const handleTabCss = (id) => {
    let tabs = document.getElementsByClassName('nav-link');
    for (const list of tabs) {
      list.classList.remove('active');
      list.classList.add('color-gray');
    }
    for (const tab of tabs) {
      tab.classList.add('active');
      if (tab.id === id + '-tab') {
        break;
      }
    }
  };

  useEffect(() => {
    handleTabCss();
  }, []);

  // const validateTabChange = (tabId) => {
  //   if (activeTab === 'profile') {
  //     if (tabId !== 'profile') {
  //       if (!validateProfileTab()) {
  //         return {
  //           isValid: false,
  //           isCurrentTabCompleted: profileTabCompleted,
  //           error: 'Please provide the personal details first!',
  //         };
  //       }
  //     }
  //   } else if (activeTab === 'billing') {
  //     if (tabId !== 'billing' && tabId !== 'profile') {
  //       if (!validateBillingTab()) {
  //         return {
  //           isValid: false,
  //           isCurrentTabCompleted: billingTabCompleted,
  //           error:
  //             'Please provide valid billing information in the billing tab.',
  //         };
  //       }
  //     }
  //   } else if (activeTab === 'questionnaire') {
  //     if (
  //       tabId !== 'questionnaire' &&
  //       tabId !== 'profile' &&
  //       tabId !== 'billing'
  //     ) {
  //       if (!validateQuestionnaireTab()) {
  //         return {
  //           isValid: false,
  //           isCurrentTabCompleted: questionnaireTabCompleted,
  //           error:
  //             'Please provide answers to the questions in the questionnaire tab.',
  //         };
  //       }
  //     }
  //   }
  //   return { isValid: true, isCurrentTabCompleted: true, error: '' };
  // };

  // const validateProfileTab = () => {
  //   return profileTabCompleted;
  // };

  // const validateBillingTab = () => {
  //   return billingTabCompleted;
  // };

  // const validateQuestionnaireTab = () => {
  //   return questionnaireTabCompleted;
  // };

  const handleInformation = (tab) => {
    setProfileTabCompleted(true);
    handleTabCss(tab);
    setActiveTab(tab);
    setCurrentTab(tab);
  };

  const handleBilling = (tab) => {
    setBillingTabCompleted(true);
    handleTabCss(tab);
    setActiveTab(tab);
    setCurrentTab(tab);
  };

  const handleQuestionnaire = (tab) => {
    setQuestionnaireTabCompleted(true);
    handleTabCss(tab);
    setActiveTab(tab);
    setCurrentTab(tab);
  };

  const tabList = [
    {
      id: 'profile',
      label: 'Information ',
      tabActive: true,
      component: <InformationRange handleInformation={handleInformation} />,
    },
    {
      id: 'billing',
      label: 'Billing',
      tabActive: true,
      component: (
        <AddressRange
          handleBilling={handleBilling}
          handleTabChange={handleTabChange}
        />
      ),
    },
    {
      id: 'questionnaire',
      label: 'Questionnaire',
      tabActive: true,
      component: (
        <QuestionnaireRange
          handleQuestionnaire={handleQuestionnaire}
          handleTabChange={handleTabChange}
        />
      ),
    },
    {
      id: 'review',
      label: 'Review & Confirm',
      tabActive: true,
      component: (
        <Referral
          setActiveTab={setActiveTab}
          handleTabChange={handleTabChange}
        />
      ),
    },
  ];

  const handleClose = () => {
    setShowError(false);
    setShowErrorDetail({
      isError: true,
      message: '',
    });
  };

  return (
    <>
      <MainLayout>
        {showError && (
          <ErrorBanner error={showErrorDetail} handleClose={handleClose} />
        )}
        <div className="tab-list-user">
          <ul className="nav nav-tabs plan-number" id="myTab" role="tablist">
            <li>
              <Link to="/">
                Plan<i className="fa fa-angle-right"></i>
              </Link>
            </li>
            {tabList.map((item, index) => (
              <li className={`nav-item`} role="presentation" key={index}>
                <button
                  className="nav-link"
                  id={`${item.id}-tab`}
                  data-bs-toggle="tab"
                  data-bs-target={`#${item.id}`}
                  type="button"
                  role="tab"
                  aria-controls={item.id}
                  aria-selected={activeTab === item.id ? 'true' : 'false'}
                  onClick={() => handleTabChange(item.id)}
                >
                  {item.label} <i className="fa fa-angle-right"></i>
                </button>
              </li>
            ))}
          </ul>
          <div className="tab-content">
            {tabList.map((tab) => (
              <div
                key={tab.id}
                className={`tab-pane ${activeTab === tab.id ? 'active' : ''}`}
                id={tab.id}
                role="tabpanel"
                aria-labelledby={`${tab.id}-tab`}
              >
                {activeTab === tab.id && tab.component}
              </div>
            ))}
          </div>
        </div>
      </MainLayout>
    </>
  );
};

export default RangeMembershipApplication;
