import React from 'react';
import PropTypes from 'prop-types';

const CardItems = (props) => {
  return (
    <>
      <div className="event-card">
        <div className="card">
          <img src={props.ImagesCard} alt="event-1" className="img-fluid" />
          <div className="card-body">
            <h4 className="card-heading">{props.CardHeading}</h4>
            <p className="card-titles">{props.DateCard}</p>
            <p className="card-text">{props.CardText}</p>
            <a href={props.HrefLink}>
              Sign Up
              <img src="../images/back_arrow.svg" alt="" />
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

CardItems.propTypes = {
  ImagesCard: PropTypes.node.isRequired,
  CardHeading: PropTypes.string,
  DateCard: PropTypes.string,
  CardText: PropTypes.string,
  HrefLink: PropTypes.string,
};

export default CardItems;
