import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPackage } from '../redux/features/packages/packageSlice';
import { useNavigate } from 'react-router-dom';

const PackagePlan = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.package.packages);
  const navigate = useNavigate();
  const { personalDetail } = useSelector((state) => state.signup);

  useEffect(() => {
    const fetchData = async () => {
      dispatch(fetchPackage());
    };
    fetchData();
  }, []);

  const handlePlan = (data) => {
    localStorage.setItem('selectedPlan', JSON.stringify(data));
    if (personalDetail?.subscriptions?.length === 0) {
      localStorage.setItem('joinEmail', personalDetail?.email);
      navigate('/plan-checkout');
    } else {
      navigate('/joinform');
    }
  };

  return (
    <>
      <div className="card-price">
        <div className="container">
          <div className="row">
            {data && (
              <>
                {data.map((item, index) => (
                  <div className="col-md-4" key={index}>
                    <div className="card">
                      {item.name === 'SILVER' && (
                        <img
                          src="../images/card-1.png"
                          className="card-img-top"
                          alt="..."
                        />
                      )}

                      {item.name === 'GOLD' && (
                        <img
                          src="../images/card-2.png"
                          className="card-img-top"
                          alt="..."
                        />
                      )}
                      {item.name === 'LIFETIME MEMBERSHIP' && (
                        <img
                          src="../images/card-3.png"
                          className="card-img-top"
                          alt="..."
                        />
                      )}
                      <div className="card-body">
                        <div>
                          <h4>{item.name}</h4>
                          {item.packages
                            ?.filter(
                              (item) =>
                                item.add_on === 0 &&
                                item.name !== 'One Time Fee'
                            )
                            .map((package_data) => (
                              <>
                                {package_data.package_prices
                                  ?.filter(
                                    (price_record) =>
                                      price_record.price_frequency === 'Monthly'
                                  )
                                  .map((price, id) => (
                                    <p key={id}>
                                      ${price.price}
                                      {price.description}
                                      <p>{package_data?.description}</p>
                                    </p>
                                  ))}
                              </>
                            ))}
                          <h5>Benefits Include:</h5>
                        </div>
                        <ul>
                          {item.features?.map((feature) => (
                            <li key={feature.id}>{feature.name}</li>
                          ))}
                        </ul>
                        <button
                          className="theme-button"
                          onClick={() => handlePlan(item)}
                        >
                          Select Plan
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PackagePlan;
