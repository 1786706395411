import React from 'react';
import MainLayout from '../Layout/MainLayout';
// import PackageForm from '../components/PackageForm';
// import Video from '../components/Video';

const Coverage = () => {
  return (
    <>
      <MainLayout>
        <div className="about-banner banner-self">
          <div className="about-outer">
            <h1 className="banner-heading">
              Explore all the ways you can experience your passion.
            </h1>
          </div>
        </div>
        <div className="map-location">
          <div className="container">
            <div className="row">
              <h3 className="sub-title-2">Find a Local Range</h3>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d46830151.11795828!2d-119.8093025!3d44.24236485!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54eab584e432360b%3A0x1c3bb99243deb742!2sUnited%20States!5e0!3m2!1sen!2sin!4v1701087596276!5m2!1sen!2sin"
                allowfullscreen=""
                loading="lazy"
                // referrerpolicy="no-referrer-when-downgrade"
                className="map-select"
              ></iframe>
            </div>
          </div>
        </div>
        <div className="select-user-plan d-none">
          <h4 className="select-heading">JOIN NOW</h4>
          <h4 className="sub-title">Select Your Plan to Get Started!</h4>
        </div>
        {/* <PackageForm /> */}

        <div className="coverage d-none">
          <div className="container">
            <div className="rwo">
              <div className="coverage-police">
                <h3 className="sub-title">
                  COVERAGES offered by Right To Bear:
                </h3>

                <div className="cover-ages-text">
                  <p>
                    Civil Defense coverage allows you to choose your own
                    attorney in the event you are sued after a self-defense
                    incident.
                  </p>
                  <p>
                    Criminal Defense coverage provides legal support in the
                    event you are criminally charged after a self-defense
                    incident. (You choose your own attorney)
                  </p>
                  <p>
                    Multi state coverage offers you protection in 50 states.
                    Residents of New York, New Jersey, and Washington do not
                    qualify for our membership.
                  </p>
                  <p>
                    Expert Witness coverage will provide an “expert witness” to
                    testify on your behalf.
                  </p>
                  <p>
                    Psychological support provides coverage for counseling
                    sessions due to stress/trauma experienced from a
                    self-defense incident.
                  </p>
                  <p>
                    Bail Bond Reimbursement provides money for bail up to
                    $100,000.
                  </p>
                  <p>
                    Additional Associate Coverage extends coverage to a spouse
                    or household partner.
                  </p>
                  <p>
                    Gun replacement – We will replace your gun if taken after a
                    self-defense incident.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div style={{ marginBottom: '35px' }}>
          <Video />
        </div> */}
      </MainLayout>
    </>
  );
};

export default Coverage;
