import React from 'react';
import PropTypes from 'prop-types';

const Bill = (props) => {
  return (
    <>
      <div className="order-summary">
        <div className="order-heading">
          <h3>{props.Heading}</h3>
        </div>
        <div className="cover-yearly">
          <h4>{props.Yearly}</h4>
          <p>{props.Dollar}</p>
        </div>
        <div className="items-plan items-border-left-remove">
          <table className="table">
            <tbody>
              <tr>
                <td>
                  <p className="plan">{props.PlanUser}</p>
                </td>
                <td>
                  <p className="price">{props.Price}</p>
                </td>
              </tr>
              {/* <tr>
                <td>
                  <p className="plan">{props.Add}</p>
                </td>
                <td>
                  <p className="price1">{props.AddPrice}</p>
                </td>
              </tr> */}
              {/* <tr>
                <td>
                  <p className="plan">{props.Time}</p>
                </td>
                <td>
                  <p className="price1">{props.TimePrice}</p>
                </td>
              </tr> */}
              {props.isAdditional && (
                <tr>
                  <td>
                    <p className="plan">{props.Additional}</p>
                  </td>
                  <td>
                    <p className="price1">{props.AdditionalPrice}</p>
                  </td>
                </tr>
              )}

              <tr>
                <td>
                  <p className="plan">{props.Tax}</p>
                </td>
                <td>
                  <p className="price1">{props.TaxPrice}</p>
                </td>
              </tr>
            </tbody>
          </table>
          <table className="table">
            <tbody>
              <tr className="last-total">
                <td>
                  <p className="total">{props.Total}</p>
                </td>
                <td>
                  <p className="all-total">{props.TotalPrice}</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

Bill.propTypes = {
  props: PropTypes.object,
  Heading: PropTypes.string,
  Yearly: PropTypes.string,
  Dollar: PropTypes.string,
  PlanUser: PropTypes.string,
  Price: PropTypes.string,
  // Add: PropTypes.string,
  AddPrice: PropTypes.string,
  Time: PropTypes.string,
  // TimePrice: PropTypes.string,
  Additional: PropTypes.string,
  AdditionalPrice: PropTypes.string,
  Tax: PropTypes.string,
  TaxPrice: PropTypes.string,
  Total: PropTypes.string,
  TotalPrice: PropTypes.string,
  isAdditional: PropTypes.bool,
};
export default Bill;
