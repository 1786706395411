import React, { useRef, useState } from 'react';
import Bill from '../../components/Bill';
import { validationInformationRangeSchema } from '../../utils/helpers';
import { useSelector } from 'react-redux';
// import { signupApi } from '../../redux/features/signup/signupSlice';
import { TailSpin } from 'react-loader-spinner';
import ErrorBanner from '../ErrorBanner';
import { state_city } from '../../utils/state_cities';
import { Link } from 'react-router-dom';
import { memo } from 'react';
import PropTypes from 'prop-types';

const InformationRange = ({ handleInformation }) => {
  const { personalDetail } = useSelector((state) => state.signup);
  const [errors, setErrors] = useState('');
  const [showError, setShowError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showErrorDetail, setShowErrorDetail] = useState({
    isError: true,
    message: '',
  });
  const [isSameAddress, setIsSameAddress] = useState(true);
  const formRef = useRef(null);

  //fetch email from local storage
  let defaultEmail = localStorage.getItem('joinEmail');

  let statelist = Object.keys(state_city);
  // const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    userTitle: personalDetail?.userTitle ? personalDetail?.userTitle : '',
    firstName: personalDetail?.firstName ? personalDetail?.firstName : '',
    middleName: personalDetail?.middleName ? personalDetail?.middleName : '',
    lastName: personalDetail?.lastName ? personalDetail?.lastName : '',
    email: defaultEmail ? defaultEmail : '',
    phoneNumber: personalDetail?.phoneNumber ? personalDetail?.phoneNumber : '',
    secondaryPhone: personalDetail?.secondaryPhone
      ? personalDetail?.secondaryPhone
      : '',
    DOB: personalDetail?.DOB ? personalDetail?.DOB : '',
    address: personalDetail?.address ? personalDetail?.address : '',
    address2: personalDetail?.address2 ? personalDetail?.address2 : '',
    state: personalDetail?.state ? personalDetail?.state : '',
    city: personalDetail?.city ? personalDetail?.city : '',
    zipCode: personalDetail?.zipCode ? personalDetail?.zipCode : '',
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    // Validation
    try {
      validationInformationRangeSchema.validateSyncAt(name, { [name]: value });
      setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
    } catch (error) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: error.message,
      }));
    }
  };

  // const handlePasswordChange = (event) => {
  //   const { name, value } = event.target;
  //   setFormData((prevFormData) => ({
  //     ...prevFormData,
  //     [name]: value,
  //   }));
  //   try {
  //     validationInformationRangeSchema.validateSyncAt(name, { [name]: value });
  //     if (name === 'password' && value !== formData.confirmPassword) {
  //       setErrors((prevErrors) => ({
  //         ...prevErrors,
  //         confirmPassword: 'Password and Confirm Password should match',
  //       }));
  //     } else {
  //       setErrors((prevErrors) => ({
  //         ...prevErrors,
  //         confirmPassword: '',
  //       }));
  //     }
  //     setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
  //   } catch (error) {
  //     if (name === 'confirmPassword' && value === formData.password) {
  //       setErrors((prevErrors) => ({
  //         ...prevErrors,
  //         confirmPassword: '',
  //       }));
  //     } else {
  //       setErrors((prevErrors) => ({
  //         ...prevErrors,
  //         [name]: error.message,
  //       }));
  //     }
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await validationInformationRangeSchema.validate(formData, {
        abortEarly: false,
      });
      setErrors('');
      const {
        userType,
        company,
        address2,
        city,
        state,
        billingAddress,
        ...personalDetail
      } = formData;
      const obj = {
        userType: userType === 'organization' ? 1 : 0,
        company: userType === 'organization' ? company : '',
        address2,
        city,
        state,
        billingAddress,
        ...personalDetail,
      };

      if (!state_city[state].includes(city)) {
        setShowError(true);
        setShowErrorDetail({
          isError: false,
          message: 'Not a valid United States city.',
        });
        setTimeout(() => {
          setShowError(false);
          setShowErrorDetail({
            isError: true,
            message: '',
          });
        }, 3000);
        setIsLoading(false);
      }
      // else if (!isSameAddress && billingAddress === '') {
      //   setShowError(true);
      //   setShowErrorDetail({
      //     isError: false,
      //     message: 'Please provide the billing options first!',
      //   });
      //   setTimeout(() => {
      //     setShowError(false);
      //     setShowErrorDetail({
      //       isError: true,
      //       message: '',
      //     });
      //   }, 3000);
      //   setIsLoading(false);

      //   return false;
      // }
      else {
        // dispatch(signupApi(obj));
        localStorage.setItem('userData', JSON.stringify(obj));
        setIsLoading(false);
        handleInformation('billing');
        window.scrollTo(0, 0);
        // handleTabAction("profile", "billing");
      }
    } catch (error) {
      const validationErrors = {};
      error.inner.forEach((err) => {
        if (err.path) {
          validationErrors[err.path] = err.message;
        } else {
          validationErrors['DOB'] = err.message;
        }
      });
      console.log(validationErrors);

      if (Object.entries(validationErrors).length === 0) {
        setShowError(true);
        setShowErrorDetail({
          isError: true,
          message: 'Please fill all the required fields.',
        });
        setTimeout(() => {
          setShowError(false);
          setShowErrorDetail({
            isError: true,
            message: '',
          });
        }, 3000);
      }
      setErrors(validationErrors);
      setIsLoading(false);
    }
  };

  const handleManualSubmit = async (e) => {
    e.preventDefault();
    const form = formRef.current;
    if (form.checkValidity()) {
      setIsLoading(true);
      await handleSubmit(e);
    } else {
      setShowError(true);
      setShowErrorDetail({
        isError: true,
        message: 'Please fill all the required fields.',
      });
      setTimeout(() => {
        setShowError(false);
        setShowErrorDetail({
          isError: true,
          message: '',
        });
      }, 3000);
      form.reportValidity();
    }
  };

  const styleClass = {
    color: 'red',
  };

  const handleClose = () => {
    setShowError(false);
    setShowErrorDetail({
      isError: true,
      message: '',
    });
  };

  return (
    <>
      <div className="join-form-user p-0 p-md-2">
        <div className="container-fluid">
          <div className="row">
            {showError && (
              <ErrorBanner error={showErrorDetail} handleClose={handleClose} />
            )}
            <div className="col-lg-8">
              <form className="details-user-form" ref={formRef}>
                <div className="row g-3">
                  <h4 className="details">RANGE DETAILS</h4>
                  <div className="col-md-12">
                    <label
                      htmlFor="inputState1"
                      className="form-label label-text"
                    >
                      Range Name: *
                    </label>
                    <select
                      id="inputState1"
                      className="form-select input-outline"
                      name="billingState"
                      required
                    >
                      <option value=""></option>
                      <option value="MarksmamRange">Marksmam Range</option>
                      <option value="range">Range</option>
                    </select>
                  </div>
                  <div className="col-md-12">
                    <label
                      htmlFor="firstName"
                      className="form-label label-text"
                    >
                      (Mr/Mrs/Ms/Dr):
                    </label>
                    <input
                      type="text"
                      className="form-control input-outline"
                      value={formData.userTitle}
                      name="userTitle"
                      onChange={handleInputChange}
                    />
                    {errors.userTitle && (
                      <div style={styleClass}>{errors.userTitle}</div>
                    )}
                  </div>
                  <div className="col-md-4">
                    <label
                      htmlFor="firstName"
                      className="form-label label-text"
                    >
                      Contact First name*
                    </label>
                    <input
                      type="text"
                      className="form-control input-outline"
                      value={formData.firstName}
                      name="firstName"
                      onChange={handleInputChange}
                      required
                    />
                    {errors.firstName && (
                      <div style={styleClass}>{errors.firstName}</div>
                    )}
                  </div>
                  <div className="col-md-4">
                    <label
                      htmlFor="middleName"
                      className="form-label label-text"
                    >
                      Middle Name
                    </label>
                    <input
                      type="text"
                      className="form-control input-outline"
                      value={formData.middleName}
                      name="middleName"
                      onChange={handleInputChange}
                    />
                    {errors.middleName && (
                      <div style={styleClass}>{errors.middleName}</div>
                    )}
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="lastname" className="form-label label-text">
                      Contact Last name*
                    </label>
                    <input
                      type="text"
                      className="form-control input-outline"
                      value={formData.lastName}
                      name="lastName"
                      onChange={handleInputChange}
                      required
                    />
                    {errors.lastName && (
                      <div style={styleClass}>{errors.lastName}</div>
                    )}
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="gender" className="form-label label-text">
                      Gender*
                    </label>
                    <div className="select-gender">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="man"
                          required
                        />
                        <label className="form-check-label" htmlFor="man">
                          Man
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="female"
                          checked
                        />
                        <label className="form-check-label" htmlFor="female">
                          Female
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="other"
                          checked
                        />
                        <label className="form-check-label" htmlFor="other">
                          Other
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="DOB" className="form-label label-text">
                      Date of Birth:*
                    </label>
                    <input
                      type="date"
                      className="form-control input-outline"
                      value={formData.DOB}
                      name="DOB"
                      onChange={handleInputChange}
                      pattern="(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)"
                      max="2100-12-31"
                      required
                    />
                    {errors.DOB && <div style={styleClass}>{errors.DOB}</div>}
                  </div>
                  <div className="col-md-12">
                    <label htmlFor="position" className="form-label label-text">
                      Position
                    </label>
                    <input
                      type="text"
                      className="form-control input-outline"
                      name="position"
                    />
                  </div>
                  {/* <div className="col-md-12">
                    <label htmlFor="name" className="form-label label-text">
                      Address*
                    </label>
                    <input
                      type="address"
                      name="address"
                      className="form-control input-outline"
                      value={formData.address}
                      onChange={handleInputChange}
                      required
                    />
                    {errors.address && (
                      <div style={styleClass}>{errors.address}</div>
                    )}
                  </div>
                  <div className="col-md-12">
                    <label htmlFor="name" className="form-label label-text">
                      Address Line 2:
                    </label>
                    <input
                      type="address"
                      name="address2"
                      className="form-control input-outline"
                      value={formData.address2}
                      onChange={handleInputChange}
                    />
                    {errors.address2 && (
                      <div style={styleClass}>{errors.address2}</div>
                    )}
                  </div>
                  <div className="col-md-4">
                    <label
                      htmlFor="inputCity1"
                      className="form-label label-text"
                    >
                      City*
                    </label>
                    <input
                      type="text"
                      className="form-control input-outline"
                      id="inputCity1"
                      name="city"
                      onChange={handleInputChange}
                      value={formData.city}
                      required
                    />
                  </div>
                  <div className="col-md-4">
                    <label
                      htmlFor="inputState1"
                      className="form-label label-text"
                    >
                      State*
                    </label>
                    <select
                      id="inputState1"
                      className="form-select input-outline"
                      name="state"
                      onChange={handleInputChange}
                      value={formData.state}
                      required
                    >
                      <option value="">Select State</option>
                      {statelist.map((item, index) => (
                        <option value={item} key={index}>
                          {item}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="zipCode" className="form-label label-text">
                      Postal/ZIP code*
                    </label>
                    <input
                      type="text"
                      className="form-control input-outline"
                      id="inputZip1"
                      name="zipCode"
                      onChange={handleInputChange}
                      value={formData.zipCode}
                      maxLength="5"
                      onKeyPress={(e) => {
                        const regex = /^[0-9\b]+$/;
                        if (!regex.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      required
                    />
                  </div>
                  <div className="col-md-6">
                    <label
                      htmlFor="inputState1"
                      className="form-label label-text"
                    >
                      County:
                    </label>
                    <input type="text" className="input-outline" />
                  </div>
                  <div className="col-md-6">
                    <label
                      htmlFor="inputState1"
                      className="form-label label-text"
                    >
                      Country:
                    </label>
                    <select
                      id="inputState1"
                      className="form-select input-outline"
                      name="billingState"
                      onChange={handleInputChange}
                      value={formData.billingState}
                    >
                      <option selected>Select State</option>
                      {statelist.map((item, index) => (
                        <option value={item} key={index}>
                          {item}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-md-6">
                    <label
                      htmlFor="phoneNumber"
                      className="form-label label-text"
                    >
                      Phone*
                    </label>
                    <input
                      type="tel"
                      maxLength="10"
                      className="form-control input-outline"
                      value={formData.phoneNumber}
                      name="phoneNumber"
                      onChange={handleInputChange}
                      onKeyPress={(e) => {
                        const regex = /^[0-9\b]+$/;
                        if (!regex.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      required
                    />
                    {errors.phoneNumber && (
                      <div style={styleClass}>{errors.phoneNumber}</div>
                    )}
                  </div>
                  <div className="col-md-6">
                    <label
                      htmlFor="secondaryPhone"
                      className="form-label label-text"
                    >
                      Secondary Phone:
                    </label>
                    <input
                      type="tel"
                      maxLength="10"
                      className="form-control input-outline"
                      value={formData.secondaryPhone}
                      name="secondaryPhone"
                      onChange={handleInputChange}
                      onKeyPress={(e) => {
                        const regex = /^[0-9\b]+$/;
                        if (!regex.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                    />
                    {errors.secondaryPhone && (
                      <div style={styleClass}>{errors.secondaryPhone}</div>
                    )}
                  </div>
                  <div className="col-md-12">
                    <label
                      htmlFor="RangeWebsite"
                      className="form-label label-text"
                    >
                      Range Website*
                    </label>
                    <input
                      type="text"
                      className="form-control input-outline"
                      required
                    />
                  </div> */}

                  {/* <div className="col-md-6">
                    <label
                      htmlFor="inputState1"
                      className="form-label label-text"
                    >
                      Membership Tab
                    </label>
                    <select
                      id="inputState1"
                      className="form-select input-outline"
                      name="billingState"
                      onChange={handleInputChange}
                      value={formData.billingState}
                    >
                      <option selected>Brochure</option>
                      {statelist.map((item, index) => (
                        <option value={item} key={index}>
                          {item}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-md-6">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="Range1"
                      />
                      <label className="form-check-label" htmlFor="Range1">
                        I would like to know more about becoming a Partner Range
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="Range2"
                      />
                      <label className="form-check-label" htmlFor="Range2">
                        Veteran-Owned Business
                      </label>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <label
                      htmlFor="MembershipInformation"
                      className="form-label label-text"
                    >
                      Membership Information *
                    </label>
                    <select
                      id="inputState1"
                      className="form-select input-outline"
                      name="billingState"
                      required
                    >
                      <option selected>1-100 Members</option>

                      <option value="101-350 Member">101-350 Member</option>
                      <option value="351-600 Member">350-600 Member</option>
                      <option value="600-999 Member">600-999 Member</option>
                      <option value="1000+ Member">1000+ Member</option>
                    </select>
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="number" className="form-label label-text">
                      Lane Count*
                    </label>

                    <input
                      type="number"
                      required
                      className="form-control input-outline"
                    />
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="number" className="form-label label-text">
                      Number of Employees*
                    </label>

                    <input
                      type="number"
                      className="form-control input-outline"
                      required
                    />
                  </div>
                  <div className="col-md-6">
                    <label
                      htmlFor="TypesOfMemberships*"
                      className="form-label label-text mb-0"
                    >
                      Types of Memberships*
                    </label>
                    <p>(select ALL that apply)</p>
                    <div className="select-mm">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="Monthly"
                          required
                        />
                        <label className="form-check-label" htmlFor="Monthly">
                          Monthly
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="Annual"
                        />
                        <label className="form-check-label" htmlFor="Annual">
                          Annual
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="None"
                        />
                        <label className="form-check-label" htmlFor="None">
                          None
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="Other"
                        />
                        <label className="form-check-label" htmlFor="Other">
                          Other
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label
                      htmlFor="Range Features"
                      className="form-label label-text mb-0"
                    >
                      Range Features
                    </label>
                    <p>Please select all that apply.</p>
                    <div className="select-mm">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          required
                          id="Indoor"
                        />
                        <label className="form-check-label" htmlFor="Indoor">
                          Indoor
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="Outdoor"
                        />
                        <label className="form-check-label" htmlFor="Outdoor">
                          Outdoor
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="Archery"
                        />
                        <label className="form-check-label" htmlFor="Archery">
                          Archery
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="Training"
                        />
                        <label className="form-check-label" htmlFor="Training">
                          Training Facility
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="Public"
                        />
                        <label className="form-check-label" htmlFor="Public">
                          Public
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="Private"
                        />
                        <label className="form-check-label" htmlFor="Private">
                          Private
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <label
                      htmlFor=" Gun Rentals"
                      className="form-label label-text mb-0"
                    >
                      Gun Rentals*
                    </label>
                    <p>(select ALL that apply)</p>
                    <div className="select-mm">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="Handgun"
                          required
                        />
                        <label className="form-check-label" htmlFor="Handgun">
                          Handgun
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="Long Gun"
                        />
                        <label className="form-check-label" htmlFor="Long Gun">
                          Long Gun
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="Suppressed"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="Suppressed"
                        >
                          Suppressed
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="Full Auto"
                        />
                        <label className="form-check-label" htmlFor="Full Auto">
                          Full Auto
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <label
                      htmlFor="number"
                      className="form-label label-text mb-0"
                    >
                      Range Social Media Handles
                    </label>
                    <p>
                      (EX: Twitter - @therangenetwork, Instagram -
                      @therangenetwork)
                    </p>
                    <textarea
                      className="form-control input-outline"
                      id="floatingTextarea"
                    ></textarea>
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="time" className="form-label label-text">
                      Hours of Operation*
                    </label>
                    <input
                      type="time"
                      className="form-control input-outline"
                      required
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="name" className="form-label label-text">
                      # of Range Locations*
                    </label>
                    <input
                      type="number"
                      className="form-control input-outline"
                      required
                    />
                  </div>
                  <div className="col-md-12">
                    <p>
                      About (We would like to hear more about you and your
                      range, feel free to comment below anything that would help
                      us understand you and/or your range more.):
                    </p>
                    <textarea
                      className="form-control input-outline"
                      id="floatingTextarea"
                    ></textarea>
                  </div>
                  <div className="col-md-12">
                    <p>List any unique features specific to your range.:</p>
                    <textarea
                      className="form-control input-outline"
                      id="floatingTextarea"
                    ></textarea>
                  </div> */}
                  {/* <hr /> */}
                  {/* <h5 className="bear-text">
                    PLEASE HELP US GROW THE SHOOTING SPORTS BY REFERRING RANGES
                    THAT WOULD BENEFIT FROM THE RANGE NETWORK COMMUNITY
                  </h5>
                  <div className="col-md-12">
                    <label
                      htmlFor="firstName"
                      className="form-label label-text"
                    >
                      Referral Range Name:
                    </label>
                    <input type="text" className="form-control input-outline" />
                  </div>
                  <div className="col-md-12">
                    <label htmlFor="number" className="form-label label-text">
                      Contact Person:
                    </label>
                    <input type="text" className="form-control input-outline" />
                  </div>
                  <div className="col-md-12">
                    <label htmlFor="number" className="form-label label-text">
                      Phone Number:
                    </label>
                    <input type="text" className="form-control input-outline" />
                  </div>
                  <div className="col-md-12">
                    <label htmlFor="email" className="form-label label-text">
                      Email Address:
                    </label>
                    <input
                      type="email"
                      className="form-control input-outline"
                      value={formData.email}
                      name="email"
                      onChange={handleInputChange}
                    />
                    {errors.email && (
                      <div style={styleClass}>{errors.email}</div>
                    )}
                  </div>
                  <div className="col-md-12">
                    <label htmlFor="file" className="form-label label-text">
                      Upload Range Logo/Profile Photo:
                    </label>
                    <input type="file" className="form-control input-outline" />
                  </div>*/}
                  <div className="col-md-12">
                    <div className="last">
                      <Link to="/membership-application" className="back">
                        Back
                      </Link>
                      <Link
                        to="#"
                        className="theme-button"
                        onClick={handleManualSubmit}
                      >
                        Continue
                      </Link>
                    </div>
                  </div>

                  {/*  */}

                  <h4 className="address-details-1 d-none">
                    HOME ADDRESS* <span>(NO PO BOXES ALLOWED)</span>
                  </h4>
                  <div className="outer-form d-none">
                    <div className="row">
                      <div className="col-md-12">
                        <label
                          htmlFor="address"
                          className="form-label label-text"
                        >
                          Street Address 1*
                        </label>
                        <input
                          type="address"
                          name="address"
                          className="form-control input-outline"
                          placeholder="Street Address 1*"
                          value={formData.address}
                          onChange={handleInputChange}
                          required
                        />
                        {errors.address && (
                          <div style={styleClass}>{errors.address}</div>
                        )}
                      </div>
                      <div className="col-md-12">
                        <label
                          htmlFor="address2"
                          className="form-label label-text"
                        >
                          Street Address 2
                        </label>
                        <input
                          type="address"
                          name="address2"
                          className="form-control input-outline"
                          placeholder="Street Address 2"
                          onChange={handleInputChange}
                          value={formData.OptionalAddress}
                        />
                      </div>
                      <div className="col-md-4">
                        <label
                          htmlFor="state_"
                          className="form-label label-text"
                        >
                          State
                        </label>
                        <select
                          id="state_"
                          className="form-select input-outline"
                          name="state"
                          value={formData.state}
                          onChange={handleInputChange}
                          required
                        >
                          <option value=""> Select State</option>
                          {statelist.map((item, index) => (
                            <option value={item} key={index}>
                              {item}
                            </option>
                          ))}
                        </select>
                        <p className="form-membership">
                          We do not offer memberships for those in the following
                          states: New York, New Jersey, Washington
                        </p>
                      </div>
                      <div className="col-md-4">
                        <label
                          htmlFor="inputCity"
                          className="form-label label-text"
                        >
                          City
                        </label>
                        <input
                          type="text"
                          className="form-control input-outline"
                          id="inputCity"
                          placeholder="City"
                          name="city"
                          onChange={handleInputChange}
                          value={formData.city}
                          required
                        />
                      </div>

                      <div className="col-md-4">
                        <label
                          htmlFor="inputZip"
                          className="form-label label-text"
                        >
                          Postal/ZIP code
                        </label>
                        <input
                          type="text"
                          className="form-control input-outline"
                          id="inputZip"
                          placeholder="Postal/ZIP code"
                          name="zipCode"
                          onChange={handleInputChange}
                          value={formData.zipCode}
                          maxLength="5"
                          onKeyPress={(e) => {
                            const regex = /^[0-9\b]+$/;
                            if (!regex.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <p className="bill-address list-bil d-none">
                    BILLING ADDRESS
                  </p>
                  <div className="col-12 d-none">
                    <div className="put-filed-same">
                      <label
                        className="form-check-label"
                        htmlFor="flexCheckDefault"
                      >
                        Same as above?
                      </label>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={isSameAddress}
                          onChange={(e) => setIsSameAddress(e.target.checked)}
                          id="flexCheckDefault"
                        />
                      </div>
                    </div>
                  </div>
                  {!isSameAddress && (
                    <div className="outer-form d-none">
                      <div className="row">
                        <div className="col-md-12">
                          <label
                            htmlFor="name"
                            className="form-label label-text"
                          >
                            Street Address 1*
                          </label>
                          <input
                            type="address"
                            name="billingAddress"
                            className="form-control input-outline"
                            placeholder="Street Address 1*"
                            value={formData.billingAddress}
                            onChange={handleInputChange}
                            required
                          />
                          {errors.billingAddress && (
                            <div style={styleClass}>
                              {errors.billingAddress}
                            </div>
                          )}
                        </div>
                        <div className="col-md-12">
                          <label
                            htmlFor="name"
                            className="form-label label-text"
                          >
                            Street Address 2
                          </label>
                          <input
                            type="address"
                            name="billingOptionalAddress"
                            className="form-control input-outline"
                            placeholder="Street Address 2"
                            onChange={handleInputChange}
                            value={formData.billingOptionalAddress}
                          />
                        </div>
                        <div className="col-md-4">
                          <label
                            htmlFor="inputState1"
                            className="form-label label-text"
                          >
                            State
                          </label>
                          <select
                            id="inputState1"
                            className="form-select input-outline"
                            name="billingState"
                            onChange={handleInputChange}
                            value={formData.billingState}
                            required
                          >
                            <option selected>Select State</option>
                            {statelist.map((item, index) => (
                              <option value={item} key={index}>
                                {item}
                              </option>
                            ))}
                          </select>
                          <p className="form-membership">
                            We do not offer memberships for those in the
                            following states: New York, New Jersey, Washington
                          </p>
                        </div>
                        <div className="col-md-4">
                          <label
                            htmlFor="inputCity1"
                            className="form-label label-text"
                          >
                            City
                          </label>
                          <input
                            type="text"
                            className="form-control input-outline"
                            id="inputCity1"
                            placeholder="City"
                            name="billingCity"
                            onChange={handleInputChange}
                            value={formData.billingCity}
                            required
                          />
                        </div>

                        <div className="col-md-4">
                          <label
                            htmlFor="inputZip1"
                            className="form-label label-text"
                          >
                            Postal/ZIP code
                          </label>
                          <input
                            type="text"
                            className="form-control input-outline"
                            id="inputZip1"
                            placeholder="Postal/ZIP code"
                            name="billingZipCode"
                            onChange={handleInputChange}
                            value={formData.billingZipCode}
                            maxLength="5"
                            onKeyPress={(e) => {
                              const regex = /^[0-9\b]+$/;
                              if (!regex.test(e.key)) {
                                e.preventDefault();
                              }
                            }}
                            required
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="button-back px-1 d-none">
                    <Link to="/joinform" className="back-text">
                      Back
                    </Link>
                    <button
                      className={`theme-button mobile-button-view ${
                        isLoading ? 'disable' : ''
                      }`}
                      type="button"
                      onClick={handleManualSubmit}
                    >
                      {isLoading ? (
                        <div className="d-flex justify-content-center ">
                          <TailSpin
                            height="30"
                            width="30"
                            color="#fff"
                            ariaLabel="tail-spin-loading"
                            radius="1"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                          />
                        </div>
                      ) : (
                        'Next'
                      )}
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div className="col-lg-4">
              <Bill
                Heading="RANGE SUMMARY"
                Yearly="Associate Coverage Monthly"
                Dollar="$15.00"
                PlanUser="Plan"
                Price="$15.00"
                Add="Add Ons"
                AddPrice="-"
                Time="One Time Fee"
                TimePrice="$15.00"
                Additional=""
                AdditionalPrice=""
                Tax="Tax ()"
                TaxPrice="$0"
                Total="Total"
                TotalPrice="$30.00"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
InformationRange.propTypes = {
  handleInformation: PropTypes.func,
};

export default memo(InformationRange);
