import AccountMainLayout from '../Layout/AccountMainLayout';
import { Link, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { getApi } from '../utils/api';
import { generatePrice } from '../utils/helpers';
import {
  API_BASE_URL,
  coverage,
  membershipCard,
  packages,
} from '../utils/endpoint';
import { useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import moment from 'moment';
import { TailSpin } from 'react-loader-spinner';
import ErrorBanner from './ErrorBanner';

const AccountCoverage = () => {
  const [selectedPackages, setSelectedPackages] = useState([]);
  const [primaryPlan, setPrimaryPlan] = useState({});
  const [upcomingInvoice, setUpcomingInvoice] = useState({});
  const [view, setView] = useState(false);
  const [modal, setModal] = useState(false);
  const { personalDetail } = useSelector((state) => state.signup);
  const navigate = useNavigate();

  useEffect(() => {
    if (personalDetail?.subscriptions?.length === 0) {
      navigate('/plans');
    }
  }, []);

  useEffect(() => {
    let access_token = Cookies.get('userToken');
    setIsLoading(true);
    getApi(coverage, access_token)
      .then((res) => {
        if (res?.data?.success) {
          setPrimaryPlan(res?.data?.data?.plan);
          setPlanYearly(
            res?.data?.data?.plan?.subscriptionInterval === 'month'
              ? 'Monthly'
              : 'Yearly'
          );
          setSelectedPackages(res?.data?.data?.packages);
          setUpcomingInvoice(res?.data?.data?.upcomingInvoice);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const [plan, setPlan] = useState([]);
  const [planYearly, setPlanYearly] = useState('Monthly');
  const [checkedItems, setCheckedItems] = useState({});
  const [selectedPlanPackage, setSelectedPlanPackage] = useState({});
  const [selectedPackage, setSelectedPackage] = useState({});
  // const [total, setTotal] = useState(0);
  const [selectedStripeIds, setSelectedStripeIds] = useState([]);
  console.log(selectedStripeIds, selectedPackage);
  let packagePlan = localStorage.getItem('selectedPlan');
  let selectedPlan = packagePlan ? JSON.parse(packagePlan) : plan;
  const [isLoading, setIsLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showErrorDetail, setShowErrorDetail] = useState({
    isError: true,
    message: '',
  });

  useEffect(() => {
    const fetchData = async () => {
      getApi(packages)
        .then((res) => {
          setPlan(res.data?.data[0]);
          let packagePlan = res.data?.data[0]?.packages[0];
          let data = {};
          data.planName = packagePlan.name + ' Monthly';
          data.id = packagePlan.id;

          let stripeArray = [];
          packagePlan?.package_prices
            .filter((item) => item.price_frequency === planYearly)
            .map((item) => {
              data.planPrice = Number(item.price);
              data.priceFrequency = item.price_frequency;
              data.stripeId = item.stripe_price_id;
              stripeArray.push(item.stripe_price_id);
            });
          setSelectedPackage({
            billingOption: data,
          });
          setSelectedStripeIds(stripeArray);

          if (selectedPackages.length > 0) {
            let packages = res.data?.data[0]?.packages;
            const addonIdsSet = new Set(
              selectedPackages.map((addon) => addon.id)
            );
            const defaultCheckedItems = packages
              ?.filter(
                (item) =>
                  item.add_on !== 0 &&
                  item.name !== 'Additional Associate Coverage'
              )
              ?.reduce((acc, item, index) => {
                acc[index] = addonIdsSet.has(item.id);
                return acc;
              }, {});
            setSelectedPlanPackage(defaultCheckedItems);
            setCheckedItems(defaultCheckedItems);
          }
        })
        .catch((er) => {
          console.log(er);
        });
    };
    fetchData();
  }, [modal]);

  // useEffect(() => {
  //   let planAmount = selectedPackage?.billingOption
  //     ? selectedPackage?.billingOption?.planPrice
  //     : 0;
  //   let addonAmount = selectedPackage?.addonPrice
  //     ? selectedPackage?.addonPrice
  //     : 0;
  //   let additionalAddonAmount = selectedPackage?.additionalAddon
  //     ? selectedPackage?.additionalAddon
  //     : 0;
  //   let sum =
  //     Number(planAmount) + Number(addonAmount) + Number(additionalAddonAmount);
  //   setTotal(Number(sum));
  // }, [selectedPackage]);

  const handleCheckboxChange = (data, index) => {
    setCheckedItems((prevCheckedItems) => ({
      ...prevCheckedItems,
      [index]: !prevCheckedItems[index],
    }));
    let customData = {
      id: data.id,
    };
    let price, stripeId;
    data.package_prices
      .filter((item) => item.price_frequency === planYearly)
      .map((item) => {
        price = Number(item.price);
        customData.planName = data.name + ' ' + item.price_frequency;
        customData.planPrice = item.price;
        customData.priceFrequency = item.price_frequency;
        customData.stripeId = item.stripe_price_id;

        stripeId = item.stripe_price_id;
      });

    setSelectedPackage((prevItems) => {
      const newAddons = prevItems.addons ? [...prevItems.addons] : [];
      const isDataInArray = newAddons.includes(customData);

      if (isDataInArray) {
        const filteredAddons = newAddons.filter((item) => item !== customData);
        let sum;

        sum = prevItems.addonPrice - price;
        return {
          ...prevItems,
          addons: filteredAddons,
          addonPrice: sum,
        };
      } else {
        const updatedAddons = [...newAddons, customData];
        let sum = prevItems.addonPrice ? prevItems.addonPrice : 0;
        sum = sum + price;
        return {
          ...prevItems,
          addons: updatedAddons,
          addonPrice: sum,
        };
      }
    });

    setSelectedStripeIds((prevItems) => {
      const newId = prevItems ? [...prevItems] : [];
      const isDataInArray = newId.includes(stripeId);
      if (isDataInArray) {
        const filteredId = newId.filter((item) => item !== stripeId);
        return filteredId;
      } else {
        return [...prevItems, stripeId];
      }
    });
  };

  const handleReview = () => {
    if (Object.values(selectedPlanPackage).every((value) => value === true)) {
      setShowError(true);
      setShowErrorDetail({
        isError: false,
        message: 'All add-on are already added.',
      });
      setTimeout(() => {
        setShowError(false);
        setShowErrorDetail({
          isError: true,
          message: '',
        });
      }, 3000);
    }
  };

  const handleClose = () => {
    setShowError(false);
    setShowErrorDetail({
      isError: true,
      message: '',
    });
  };
  const handleCancel = () => {
    if (selectedPackages.length > 0) {
      let packages = plan?.packages;
      const addonIdsSet = new Set(selectedPackages.map((addon) => addon.id));
      const defaultCheckedItems = packages
        ?.filter(
          (item) =>
            item.add_on !== 0 && item.name !== 'Additional Associate Coverage'
        )
        ?.reduce((acc, item, index) => {
          acc[index] = addonIdsSet.has(item.id);
          return acc;
        }, {});
      setSelectedPlanPackage(defaultCheckedItems);
      setCheckedItems(defaultCheckedItems);
    }
  };

  return (
    <>
      <AccountMainLayout>
        <div className="account-login pt-2 px-0">
          {isLoading && (
            <div className="loader">
              <TailSpin
                height="150"
                width="150"
                color="#aeaeae"
                ariaLabel="tail-spin-loading"
                radius="10"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </div>
          )}
          {!isLoading && (
            <>
              {showError && (
                <ErrorBanner
                  error={showErrorDetail}
                  handleClose={handleClose}
                />
              )}
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card user-card">
                      <div className="card-header">
                        <h4>
                          {primaryPlan && primaryPlan?.name
                            ? primaryPlan?.name.toUpperCase() +
                              ' ' +
                              (primaryPlan?.subscriptionInterval &&
                              primaryPlan?.subscriptionInterval === 'month'
                                ? 'MONTHLY'
                                : 'YEARLY') +
                              ' PLAN'
                            : 'Loading...'}
                        </h4>
                        <h4 className="date-select">
                          VALID THRU:{' '}
                          <span>
                            {upcomingInvoice &&
                            upcomingInvoice?.next_payment_attempt
                              ? moment
                                  .unix(upcomingInvoice?.period_end)
                                  .format('MMMM DD, YYYY')
                                  .toUpperCase()
                              : 'Loading...'}
                          </span>
                        </h4>
                      </div>

                      <div className="row">
                        <div className="col-lg-6">
                          <div className="coverage-yearly">
                            <div className="logo-cover">
                              <img src="../images/card-logo.png" alt="" />
                              <div className="cover-text order-first order-md-last">
                                <h5>
                                  {primaryPlan &&
                                    primaryPlan?.name +
                                      ' ' +
                                      (primaryPlan?.subscriptionInterval &&
                                      primaryPlan?.subscriptionInterval ===
                                        'month'
                                        ? 'Monthly'
                                        : 'Yearly')}
                                </h5>
                                <p>
                                  {primaryPlan && primaryPlan?.subscribedPrice
                                    ? generatePrice(
                                        primaryPlan?.subscribedPrice / 100
                                      )
                                    : 'Loading...'}
                                </p>
                              </div>
                            </div>

                            {/* <h5>Benefits Included</h5>
                            <ul className="user-list-gun">
                              {primaryPlan &&
                                primaryPlan?.plan?.features?.map(
                                  (item, index) => {
                                    if (index < 3) {
                                      return (
                                        <li key={index}>
                                          <span>{item?.name}</span>
                                          <span>
                                            <img
                                              src="../images/check.svg"
                                              alt=""
                                            />
                                          </span>
                                        </li>
                                      );
                                    } else if (view) {
                                      return (
                                        <li key={index}>
                                          <span>{item?.name}</span>
                                          <span>
                                            <img
                                              src="../images/check.svg"
                                              alt=""
                                            />
                                          </span>
                                        </li>
                                      );
                                    }
                                  }
                                )}
                              <div className="click-button">
                                <button
                                  type="button"
                                  className="view"
                                  onClick={() => setView(!view)}
                                >
                                  View {view ? 'Less' : 'Full'} Plan Benefits
                                </button>
                              </div>
                            </ul> */}
                          </div>
                        </div>
                        <div className="col-lg-6 ">
                          <div className="coverage-yearly">
                            <h5>Benefits Included</h5>
                            <ul className="user-list-gun">
                              {primaryPlan &&
                                primaryPlan?.plan?.features?.map(
                                  (item, index) => {
                                    if (index < 3) {
                                      return (
                                        <li key={index}>
                                          <span>{item?.name}</span>
                                          <span>
                                            <img
                                              src="../images/check.svg"
                                              alt=""
                                            />
                                          </span>
                                        </li>
                                      );
                                    } else if (view) {
                                      return (
                                        <li key={index}>
                                          <span>{item?.name}</span>
                                          <span>
                                            <img
                                              src="../images/check.svg"
                                              alt=""
                                            />
                                          </span>
                                        </li>
                                      );
                                    }
                                  }
                                )}
                              <div className="click-button">
                                <button
                                  type="button"
                                  className="view"
                                  onClick={() => setView(!view)}
                                >
                                  View {view ? 'Less' : 'Full'} Plan Benefits
                                </button>
                              </div>
                            </ul>
                            <div className=" d-none">
                              <h3 className="additional-heading">
                                Additional Coverage
                              </h3>
                              <ul className="additional-menu-list">
                                {selectedPackages &&
                                  selectedPackages?.map((item, index) => (
                                    <li key={index}>
                                      - {item?.name} -{' '}
                                      {generatePrice(
                                        item?.subscribedPrice / 100
                                      )}
                                      /
                                      {item?.subscriptionInterval === 'month'
                                        ? 'month'
                                        : 'year'}
                                    </li>
                                  ))}
                                {!selectedPackages && <li>Loading....</li>}
                                <h3 className="additional-heading-2">
                                  Optional Add-on Coverage
                                </h3>
                              </ul>
                              <button
                                type="button"
                                className="button-golden w-100 py-3 login-button"
                                data-bs-toggle="modal"
                                data-bs-target="#exampleModal"
                                onClick={() => setModal(true)}
                              >
                                upgrade coverage
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="member-doc">
                      <ul className="mem-doc">
                        <li>Membership Documents</li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="member-doc mt-0">
                      <ul className="mem-doc ">
                        <li className="font d-none">
                          Membership ID Card{' '}
                          <a
                            href={
                              API_BASE_URL + membershipCard + personalDetail?.id
                            }
                            target="_blank"
                            rel="noreferrer"
                          >
                            Download
                          </a>
                        </li>
                        <li className="font">
                          Membership ID Card <a href="#">Download</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="safety">
                <div className="container-fluid">
                  <div className="row align-items-center">
                    <div className="col-lg-6 g-0">
                      <div className="shooting mt">
                        <img src="../images/hero82.webp" alt="" />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="safety-text">
                        <h3 className="sub-title">HANDGUN SAFETY</h3>
                        <p>
                          The Basics of Handgun Safety are the foundation upon
                          which all other aspects of being a responsible gun
                          owner rely upon. Download your exclusive safety manual
                          now!
                        </p>
                        <Link
                          // to="https://cdn.shopify.com/s/files/1/0645/6069/3505/files/Right_To_Bear_Member_Exclusive_Handgun_Safety_Booklet.pdf?v=1661348472"
                          className="button-golden py-3 px-5 login-button"
                          // target="_blank"
                          to="#"
                        >
                          Download Now
                        </Link>
                      </div>
                      <img
                        src="../images/line.svg"
                        alt=""
                        className="img-fluid line-tine"
                      />
                    </div>
                    <div className="col-lg-6 g-0">
                      <div className="shooting mt-img">
                        <img src="../images/Screenshot.png" alt="" />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="safety-text">
                        <h3 className="sub-title">KNOW THE LAW</h3>
                        <p>
                          Part of being prepared is knowing the letter of the
                          law for how and when you can carry a firearm. Download
                          our concealed carry restrictions pamphlet for a state
                          by state breakdown!
                        </p>
                        <Link
                          // to="https://cdn.shopify.com/s/files/1/0645/6069/3505/files/RTB_Carry_Restrictions_By_State.pdf?v=1663097585"
                          className="button-golden py-3 px-5 login-button"
                          // target="_blank"
                        >
                          Download Now
                        </Link>
                      </div>
                      <img
                        src="../images/line.svg"
                        alt=""
                        className="img-fluid line-tine"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="modal fade"
                id="exampleModal"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
              >
                <div className="modal-dialog modal-dialog-centered modal-lg size-modal">
                  <div className="modal-content billing-list">
                    <div className="modal-header billing-heading">
                      <h5 className="modal-title" id="exampleModalLabel">
                        Change Coverage
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={handleCancel}
                      ></button>
                    </div>
                    <div className="modal-body billing-body">
                      <div className="row">
                        {selectedPlan.packages
                          ?.filter(
                            (item) =>
                              item.add_on !== 0 &&
                              item.name !== 'Additional Associate Coverage'
                          )
                          ?.map((item, index) => (
                            <div className="col-md-6 col-lg-4" key={index}>
                              {item.package_prices
                                ?.filter(
                                  (item) => item.price_frequency === planYearly
                                )
                                .map((price, ind) => (
                                  <div
                                    className={
                                      selectedPlanPackage[index]
                                        ? 'form-group disabled'
                                        : 'form-group'
                                    }
                                    key={ind}
                                  >
                                    <input
                                      type="checkbox"
                                      name="cab_booking"
                                      id={'later' + index}
                                      className="check-box-list"
                                      onChange={() =>
                                        handleCheckboxChange(item, index)
                                      }
                                      disabled={selectedPlanPackage[index]}
                                      checked={checkedItems[index]}
                                    />
                                    <label
                                      htmlFor={'later' + index}
                                      className="later-book book-name"
                                    >
                                      {item.name}
                                      {planYearly === 'Yearly'
                                        ? ' Yearly '
                                        : ' Monthly '}
                                      - ${price.price}
                                    </label>
                                  </div>
                                ))}
                            </div>
                          ))}

                        {/* <h4 className="people py-4">ADD PEOPLE</h4>
                  {selectedPlan.packages
                    ?.filter(
                      (item) => item.name === "Additional Associate Coverage"
                    )
                    .map((_package, index) => (
                      <div className="col-md-12 col-lg-6 mb-4" key={index}>
                        {_package.package_prices
                          ?.filter(
                            (item) => item.price_frequency === planYearly
                          )
                          .map((price, ind) => (
                            <div className="radioBtn" key={ind}>
                              <input
                                className="radio-input"
                                type="checkbox"
                                name="myradio-2"
                                id={"myradio3" + index}
                                value={_package.name}
                                checked={isAddPeople}
                                onChange={(e) =>
                                  handleChangeAddPeople(e, _package)
                                }
                              />
                              <label
                                htmlFor={"myradio3" + index}
                                className="radio__label"
                              >
                                <p>
                                  {_package.name}
                                  {planYearly === "Yearly"
                                    ? " Yearly "
                                    : " Monthly "}
                                  - ${price.price}
                                </p>
                              </label>
                            </div>
                          ))}
                      </div>
                    ))}

                  <div className="col-md-12 col-lg-6"></div>
                  {isAddPeople && (
                    <>
                      <div className="col-6 col-md-6 col-lg-3">
                        <input
                          type="name"
                          className="input-outline mt-2 mb-4"
                          placeholder="First Name"
                          value={peopleDetail.firstName}
                          name="firstName"
                          onChange={handleInputChange}
                          required
                        />
                      </div>
                      <div className="col-6 col-md-6 col-lg-3">
                        <input
                          type="name"
                          className="input-outline mt-2 mb-4"
                          placeholder="Last Name"
                          value={peopleDetail.lastName}
                          name="lastName"
                          onChange={handleInputChange}
                          required
                        />
                      </div>
                      <div className="col-md-12"></div>
                      {selectedPlan.packages
                        ?.filter(
                          (item) =>
                            item.add_on !== 0 &&
                            item.name !== "Additional Associate Coverage"
                        )
                        ?.map((item, index) => (
                          <div className="col-md-6 col-lg-4" key={index}>
                            {item.package_prices
                              ?.filter(
                                (item) => item.price_frequency === planYearly
                              )
                              .map((price, ind) => (
                                <div className="form-group">
                                  <input
                                    type="checkbox"
                                    name="cab_booking"
                                    id={"laterOne" + index}
                                    className="check-box-list"
                                    onChange={() =>
                                      handleCheckAddPeopleChange(item, index)
                                    }
                                    checked={checkedItemsAddPeople[index]}
                                  />
                                  <label
                                    htmlFor={"laterOne" + index}
                                    className="later-book book-name"
                                  >
                                    {item.name}
                                    {planYearly === "Yearly"
                                      ? " Yearly "
                                      : " Monthly "}
                                    - ${price.price}
                                  </label>
                                </div>
                              ))}
                          </div>
                        ))}
                    </>
                  )} */}
                      </div>
                    </div>
                    <div className="modal-footer billing-footer">
                      <button
                        type="button"
                        className="theme-button me-md-2 mb-2 login-button"
                        onClick={handleReview}
                      >
                        review changes
                      </button>
                      <button
                        type="button"
                        className="theme-button border-golden-1 login-button"
                        data-bs-dismiss="modal"
                        onClick={handleCancel}
                      >
                        cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </AccountMainLayout>
    </>
  );
};

export default AccountCoverage;
