import React, { useState } from 'react';
import { generatePrice, handleTabAction } from '../../utils/helpers';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ErrorBanner from '../ErrorBanner';
import PropTypes from 'prop-types';

const ReviewConfirm = ({ handleTabChange }) => {
  const { personalDetail, billingDetail } = useSelector(
    (state) => state.signup
  );
  const [termCondition, setTermCondition] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showErrorDetail, setShowErrorDetail] = useState({
    isError: true,
    message: '',
  });
  const navigate = useNavigate();
  const handleBack = () => {
    handleTabChange('questionnaire');
    handleTabAction('review', 'questionnaire');
  };

  const handlePay = () => {
    if (termCondition) {
      navigate('/checkouts');
    } else {
      setShowErrorDetail({
        isError: false,
        message: 'Please agree to Terms & Conditions.',
      });
      setShowError(true);
      setTimeout(() => {
        setShowError(false);
        setShowErrorDetail({
          isError: true,
          message: '',
        });
      }, 3000);
    }
  };

  const handleClose = () => {
    setShowError(false);
    setShowErrorDetail({
      isError: true,
      message: '',
    });
  };

  return (
    <>
      <div className="join-form-user">
        <div className="container-fluid">
          {showError && (
            <ErrorBanner error={showErrorDetail} handleClose={handleClose} />
          )}
          <div className="row">
            <h4 className="billing-plan pt-2">REVIEW & CONFIRM</h4>
            <div className="user-all-id">
              <table className="table">
                <thead>
                  <tr className="table-user-line">
                    <th
                      style={{
                        verticalAlign: 'baseline',
                        whiteSpace: 'nowrap',
                        paddingLeft: '0px',
                      }}
                    >
                      <p className="user-name">Member Detail</p>
                    </th>
                    <th>
                      <div className="full-address-user">
                        <p>
                          <span>
                            {personalDetail?.firstName +
                              ' ' +
                              personalDetail?.lastName}
                          </span>
                          <br />
                          {personalDetail?.email}
                          <br />
                          {personalDetail?.phoneNumber}
                          <br />
                          <br />
                          {personalDetail.address +
                            ' ' +
                            personalDetail.city +
                            ', ' +
                            personalDetail.state}
                          <br />
                          {personalDetail.zipCode}
                        </p>
                      </div>
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {/* <tr>
                    <td>
                      <p className="table-date-title">Additional Associate</p>
                    </td>
                    <td>
                      <p className="yearly-list-user">
                        {billingDetail.isAddPeople
                          ? billingDetail?.peopleDetail?.firstName +
                            ' ' +
                            billingDetail?.peopleDetail?.lastName
                          : '-'}
                      </p>
                    </td>
                    <td>
                      <p className="yearly-list-user"></p>
                    </td>
                  </tr> */}
                  <tr>
                    <td>
                      <p className="table-date-title">Plans</p>
                    </td>
                    <td>
                      <p className="yearly-list-user">
                        {
                          billingDetail?.selectedPackage?.billingOption
                            ?.planName
                        }
                      </p>
                    </td>
                    <td>
                      <p className="dollar">
                        {generatePrice(
                          billingDetail?.selectedPackage?.billingOption
                            ?.planPrice
                        )}
                      </p>
                    </td>
                  </tr>

                  {/* <tr>
                    <td>
                      <p className="table-date-title-add">Add Ons</p>
                    </td>
                    <td colSpan="2">
                      <ul className="bond">
                        {billingDetail?.selectedPackage?.addons?.map((item) => (
                          <li className="yearly-list-user-add" key={item.id}>
                            {item.planName}
                            <span> {generatePrice(item.planPrice)}</span>
                          </li>
                        ))}
                        {billingDetail?.selectedPackage?.additionalAddons?.map(
                          (item) => (
                            <li className="yearly-list-user-add" key={item.id}>
                              {item.planName +
                                ' - ' +
                                billingDetail?.peopleDetail?.firstName +
                                ' ' +
                                billingDetail?.peopleDetail?.lastName}
                              <span> {generatePrice(item.planPrice)}</span>
                            </li>
                          )
                        )}
                        {billingDetail?.selectedPackage?.additionalAddonsPeople?.map(
                          (item) => (
                            <li className="yearly-list-user-add" key={item.id}>
                              {item.planName +
                                ' -  ' +
                                billingDetail?.peopleDetail?.firstName +
                                ' ' +
                                billingDetail?.peopleDetail?.lastName}
                              <span> {generatePrice(item.planPrice)}</span>
                            </li>
                          )
                        )}
                        {billingDetail?.selectedPackage?.addons?.length === 0 &&
                          '-'}
                      </ul>
                    </td>
                  </tr> */}
                  {/* <tr>
                    <td>
                      <p className="table-date-title">Tax</p>
                    </td>
                    <td>
                      <p className="yearly-list-user">
                        {personalDetail.state} 
                        State Tax (5.00%)
                      </p>
                    </td>
                    <td>
                      <p className="dollar">
                        {generatePrice(billingDetail?.tax)}
                      </p>
                    </td>
                  </tr> */}
                  {/* <tr>
                    <td>
                      <p className="table-date-title">One Time Fee</p>
                    </td>
                    <td>
                      <p className="yearly-list-user">-</p>
                    </td>
                    <td>
                      <p className="dollar">$15.00</p>
                    </td>
                  </tr> */}
                  {/* {billingDetail.isAddPeople && (
                    <tr>
                      <td>
                        <p className="table-date-title">
                          Additional Associate One Time Fee
                        </p>
                      </td>
                      <td>
                        <p className="yearly-list-user">-</p>
                      </td>
                      <td>
                        <p className="dollar">$15.00</p>
                      </td>
                    </tr>
                  )} */}
                  <tr>
                    <td>
                      <p className="table-date-title">Total Due Today</p>
                    </td>
                    <td>
                      <p className="yearly-list-user">-</p>
                    </td>
                    <td>
                      <p className="dollar-1">
                        {generatePrice(billingDetail?.total)}
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="col-12">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  onChange={(e) => setTermCondition(e.target.checked)}
                  id="gridCheck"
                  checked={termCondition}
                />
                <label className="form-check-label" htmlFor="gridCheck">
                  By proceeding you are agreeing to The Range Network and
                  Caliber Card Terms & Conditions and agree to enter into a
                  relationship with The Range Network to provide the
                  subscription plan as selected above
                </label>
              </div>
            </div>
            <div className="button-back mt-3 ">
              <a onClick={handleBack} className="back-text d-none d-md-block">
                Back
              </a>
              <a className="theme-button d-none d-md-block" onClick={handlePay}>
                Pay now
              </a>
            </div>
            <div className="button-back mt-3 d-block d-md-none new-add-button">
              <a
                className="button-golden px-5 mt-4 login-button"
                onClick={handlePay}
              >
                Pay now
              </a>
              <a onClick={handleBack} className="back-text">
                Back
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

ReviewConfirm.propTypes = {
  handleTabChange: PropTypes.func,
};

export default ReviewConfirm;
