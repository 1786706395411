import React from 'react';
import MainLayout from '../Layout/MainLayout';
import { Link } from 'react-router-dom';

const Participating = () => {
  return (
    <>
      <MainLayout>
        <div className="participating">
          <div className="container">
            <div className="row">
              <div className="range-par">
                <h3 className="sub-title-2">
                  PARTNER WITH US TO GET MORE PEOPLE TO THE RANGE, MORE OFTEN!
                </h3>
                <img src="../images/card-4.jpg" alt="" />
                <h4 className="sub-title-2">PARTICIPATING RANGE</h4>
                <h5 className="free">FREE TO PARTICIPATE</h5>
                <p className="range-must">
                  Participating Ranges must guarantee at least Core (Silver)
                  Level benefits to TRN members
                </p>
                <p className="includes">Includes:</p>
                <ul>
                  <li>
                    Listed on the map as a &ldquo;Participating Network
                    Range&ldquo; at TheRangeNetwork.Com
                  </li>
                  <li>
                    Receive a percentage of the proceeds from membership dues
                    based on the number of members who visit your range
                  </li>
                  <li>
                    Option to profit from sales of TRN memberships at excellent
                    margins
                  </li>
                  <li>
                    Support the industry and show that you are dedicated to the
                    growth and protection of the 2A community
                  </li>
                  <li>
                    Market your events on our website to drive more participants
                  </li>
                  <li>
                    Receive Information/newsletters and can participate in TRN
                    Networking events to help support and grow your business
                  </li>
                  <li>
                    Can apply for grants from TRN in the future to improve
                    access and overall experience of your range
                  </li>
                  <li>Increased foot traffic from The Range Network Members</li>
                  <li>Upgrade TRN level at anytime</li>
                </ul>
                <Link to="/congratulations" className="theme-button">
                  Select Plan
                </Link>
              </div>
            </div>
          </div>
        </div>
      </MainLayout>
    </>
  );
};

export default Participating;
