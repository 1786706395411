import React, { useEffect, useState } from 'react';
import AccountMainLayout from '../Layout/AccountMainLayout';
import { Link, useNavigate } from 'react-router-dom';
import {
  STRIPE_PUBLISHABLE_KEY,
  billingOverview,
  paymentMethodApi,
} from '../utils/endpoint';
import { deleteApi, getApi, putApi } from '../utils/api';
import Cookies from 'js-cookie';
import { generatePrice } from '../utils/helpers';
import { TailSpin } from 'react-loader-spinner';
import ErrorBanner from './ErrorBanner';
import { useSelector } from 'react-redux';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PaymentForm from '../components/PaymentForm';

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

const AccountBilling = () => {
  const [show, setShow] = useState(false);
  const [invoice, setInvoice] = useState([]);
  const [upcomingInvoice, setUpcomingInvoice] = useState({});
  const [paymentMethodList, setPaymentMethodList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDelete, setIsDelete] = useState(false);

  const [showError, setShowError] = useState(false);
  const [showErrorDetail, setShowErrorDetail] = useState({
    isError: true,
    message: '',
  });
  const { personalDetail } = useSelector((state) => state.signup);
  const navigate = useNavigate();
  const access_token = Cookies.get('userToken');

  useEffect(() => {
    if (personalDetail?.subscriptions?.length === 0) {
      navigate('/plans');
    }
  }, []);
  useEffect(() => {
    setIsLoading(true);
    getApi(billingOverview, access_token)
      .then((res) => {
        if (res?.data?.success) {
          setInvoice(res?.data.data.invoices);
          setUpcomingInvoice(res?.data.data.upcomingInvoice);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    fetchPaymentMethod();
  }, []);

  const fetchPaymentMethod = () => {
    getApi(paymentMethodApi, access_token)
      .then((res) => {
        if (res?.data?.success) {
          setPaymentMethodList(res?.data.data);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const handleTabAction = (currentTabId, nextTabId) => {
  //   const currentTabButton = document.querySelector(`#${currentTabId}-tab`);
  //   currentTabButton.classList.remove("active");

  //   const currentTabContent = document.querySelector(`#${currentTabId}`);
  //   currentTabContent.classList.remove("active");
  //   currentTabContent.classList.remove("show");

  //   const nextTabButton = document.querySelector(`#${nextTabId}-tab`);
  //   nextTabButton.classList.add("active");

  //   const nextTabContent = document.querySelector(`#${nextTabId}`);
  //   nextTabContent.classList.add("show", "active");
  //   window.scrollTo(0, 0);
  // };

  const handleClose = () => {
    setShowError(false);
    setShowErrorDetail({
      isError: true,
      message: '',
    });
  };

  // const handleUpdatePayment = (e) => {
  //   setShowErrorDetail({
  //     isError: false,
  //     message: 'We have sent you an email to update your payment method',
  //   });
  //   setShowError(true);
  //   setTimeout(() => {
  //     setShowError(false);
  //     setShowErrorDetail({
  //       isError: true,
  //       message: '',
  //     });
  //   }, 4000);
  // };

  const handleNavigate = () => {
    const tabEle = document.querySelectorAll('.nav-link');
    // Remove active class from tab list
    const activeElements = document.querySelectorAll('.active');
    activeElements.forEach((element) => {
      element.classList.remove('active');
    });

    // Add active class to Payment History tab
    tabEle.forEach((element) => {
      if (element.id === 'nav-profile-tab') {
        element.classList.add('active');
        const tabContent = document.getElementById('nav-profile');
        tabContent.classList.add('active', 'show');
      }
    });
  };

  const handleDelete = (e, id) => {
    e.preventDefault();
    setIsDelete(true);
    deleteApi(paymentMethodApi + '/' + id, access_token)
      .then((res) => {
        if (res?.data?.success) {
          fetchPaymentMethod();
          setIsDelete(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleUpdateDefault = (e, id) => {
    e.preventDefault();
    putApi(paymentMethodApi + '/' + id + '/default', {}, access_token)
      .then((res) => {
        if (res?.data?.success) {
          fetchPaymentMethod();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const cardBrandImages = {
    visa: '../images/cards/visa.svg',
    mastercard: '../images/cards/master-card.svg',
    amex: '../images/cards/amex.svg',
    discover: '../images/cards/discover.svg',
    circle: '../images/cards/circle.svg',
  };

  return (
    <>
      <AccountMainLayout>
        <div className="account-login pt-0">
          {isLoading && (
            <div className="loader">
              <TailSpin
                height="150"
                width="150"
                color="#aeaeae"
                ariaLabel="tail-spin-loading"
                radius="10"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </div>
          )}

          {!isLoading && (
            <div className="container-fluid">
              {showError && (
                <ErrorBanner
                  error={showErrorDetail}
                  handleClose={handleClose}
                />
              )}
              <div className="row">
                <nav className="tabs-bills">
                  <div className="nav nav-tabs" id="nav-tab" role="tablist">
                    <button
                      className="nav-link active"
                      id="nav-home-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-home"
                      type="button"
                      role="tab"
                      aria-controls="nav-home"
                      aria-selected="true"
                    >
                      Billing Overview
                    </button>
                    <button
                      className="nav-link"
                      id="nav-profile-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-profile"
                      type="button"
                      role="tab"
                      aria-controls="nav-profile"
                      aria-selected="false"
                    >
                      Payment History
                    </button>
                    <button
                      className="nav-link"
                      id="nav-contact-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-contact"
                      type="button"
                      role="tab"
                      aria-controls="nav-contact"
                      aria-selected="false"
                    >
                      Payment Methods
                    </button>
                  </div>
                </nav>
                <div className="tab-content pane-bill" id="nav-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="nav-home"
                    role="tabpanel"
                    aria-labelledby="nav-home-tab"
                  >
                    <h3 className="up-coming-bill">Upcoming Bills</h3>
                    <div className="card bills-card">
                      <table className="amount-card">
                        <thead>
                          <tr>
                            <th>Due Date</th>
                            <th>Description</th>
                            <th>Amount Due</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              {upcomingInvoice?.date && upcomingInvoice?.date}
                            </td>

                            <td>{upcomingInvoice.product_name}</td>
                            <td>
                              {upcomingInvoice?.amount &&
                                upcomingInvoice?.amount}
                            </td>
                            <td>
                              {upcomingInvoice?.status === 'draft'
                                ? 'Upcoming'
                                : ''}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="history">
                      <h3 className="up-coming-bill">
                        Payment History (Last 90 Days)
                      </h3>
                      <button type="button" onClick={handleNavigate}>
                        View Full Payment History
                      </button>
                    </div>
                    <div className="card bills-card">
                      <table className="amount-card">
                        <thead>
                          <tr>
                            <th>Payment Date</th>
                            <th>Description</th>
                            <th>Amount</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {invoice.map((item, index) => (
                            <tr key={index}>
                              <td>{item?.date}</td>
                              <td>{item.product_name}</td>
                              <td>{item?.amount}</td>
                              <td>
                                {item?.status.charAt(0).toUpperCase() +
                                  item?.status.slice(1)}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="nav-profile"
                    role="tabpanel"
                    aria-labelledby="nav-profile-tab"
                  >
                    <div className="card bills-card mt-3 table-responsive">
                      <table className="amount-card">
                        <thead>
                          <tr>
                            <th>Date</th>
                            <th>Description</th>
                            <th>Amount</th>
                            <th>Mode Of Payment</th>
                            <th>Card Description</th>
                            <th>Status</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {invoice.map((item, index) => (
                            <tr key={index}>
                              <td>{item?.date}</td>
                              <td>{item.product_name}</td>
                              <td>{item?.amount}</td>
                              <td>Credit Card</td>
                              <td>
                                {item?.card_info?.brand
                                  .charAt(0)
                                  .toUpperCase() +
                                  item?.card_info?.brand.slice(1)}
                                (****{item?.card_info?.last4}) - Expires:
                                {' ' + item?.card_info?.exp_month}/
                                {item?.card_info?.exp_year}
                              </td>
                              <td>
                                {item?.status.charAt(0).toUpperCase() +
                                  item?.status.slice(1)}
                              </td>
                              <td>
                                <Link
                                  to="#"
                                  className="details-list2"
                                  onClick={() => setShow(!show)}
                                >
                                  Details
                                </Link>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    {show ? (
                      <div className="card bills-card mt-5">
                        <table className="amount-card ">
                          <thead>
                            <tr>
                              <th>Plan</th>
                              <th>Amount</th>
                              <th>Term</th>
                              <th>Order</th>
                              <th>Tax</th>
                              <th>Total</th>
                            </tr>
                          </thead>
                          <tbody>
                            {invoice.map((item, index) => (
                              <tr key={index}>
                                <td>{item.product_name}</td>
                                <td>{item?.amount}</td>
                                <td>yearly</td>
                                <td>{item?.number}</td>

                                <td>{item?.tax && generatePrice(item?.tax)}</td>
                                <td>
                                  {item?.total &&
                                    generatePrice(item?.total / 100)}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    ) : null}
                  </div>
                  <div
                    className="tab-pane fade"
                    id="nav-contact"
                    role="tabpanel"
                    aria-labelledby="nav-contact-tab"
                  >
                    <div className="card bills-card mt-3">
                      <table className="amount-card">
                        <thead>
                          <tr>
                            <th>Type</th>
                            <th colSpan="9">Description</th>

                            <th style={{ textAlign: 'right' }}>
                              {/* <Link
                                onClick={handleUpdatePayment}
                                className="button-golden size-font m-0"
                                data-bs-toggle="modal"
                                data-bs-target="#staticBackdrop-payment-update"
                              >
                                update payment method
                              </Link> */}
                              <Link
                                className="button-golden size-font m-0"
                                data-bs-toggle="modal"
                                data-bs-target="#staticBackdrop-payment-update"
                              >
                                Add payment method
                              </Link>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {paymentMethodList?.map((item, index) => (
                            <tr key={index}>
                              <td>
                                {item?.card?.funding === 'credit'
                                  ? 'Credit '
                                  : 'Debit '}
                                Card
                              </td>

                              <td style={{ textAlign: 'left' }}>
                                <img
                                  className="me-2"
                                  src={cardBrandImages[item?.card?.brand]}
                                  alt={`${item?.card?.brand} Card `}
                                />
                                **** **** **** {item?.card?.last4}
                              </td>
                              <td colSpan="2" style={{ textAlign: 'center' }}>
                                {item.is_default ? 'Primary' : 'Secondary'}
                              </td>
                              <td className="delete-payment">
                                <button
                                  className={`button-golden border-golden size-font ${
                                    isDelete ? 'disabled-delete' : ''
                                  }`}
                                  onClick={(e) => handleDelete(e, item.id)}
                                >
                                  Delete{' '}
                                  {/* <i class="fa fa-trash" aria-hidden="true" style={{ paddingLeft: "10px" }}></i> */}
                                </button>
                              </td>
                              <td>
                                {!item.is_default && (
                                  <button
                                    className={`details-list2`}
                                    onClick={(e) =>
                                      handleUpdateDefault(e, item.id)
                                    }
                                  >
                                    Set as primary
                                  </button>
                                )}
                              </td>
                              <td></td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div
            className="modal fade"
            id="staticBackdrop-payment-update"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered modal-lg">
              <div className="modal-content">
                <div className="modal-body d-flex">
                  <Elements stripe={stripePromise}>
                    <PaymentForm
                      Heading="Add a new payment method"
                      ButtonFirst="Add"
                      fetchPaymentMethod={fetchPaymentMethod}
                    />
                  </Elements>

                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AccountMainLayout>
    </>
  );
};

export default AccountBilling;
